import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Form, Select, Input, Space, Spin } from 'antd';
import styles from '@/common/Layout/index.module.less';
import { useDispatch } from 'react-redux';
import {
  getProductBatches,
  getProcesses,
  createProducts,
  getProducts
} from '../redux/slice';
import { getAllDispenseBooths } from '@/features/dispensing/redux/slice';
import Layout from '@/common/Layout';
import FormItem from '@/common/FormItem';
import CustomButton from '@/common/CustomButton';
import { getLoggedInUser } from 'utilities/helpers';
import moment from 'moment';
import { CloudDownloadOutlined, EditOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
const CreateProductLabel = () => {
  const [form, form1] = Form.useForm();
  const dispatch = useDispatch();
  const [productsData, setProductsData] = useState([]);
  const [search, setSearch] = useState('');
  const [processes, setProcesses] = useState([]);
  // const [processDetails, setProcessDetails] = useState([]);
  const [data, setData] = useState();
  const [showNextCard, setShowNextCard] = useState(false);
  const [dispenseBooths, setDispenseBooths] = useState([]);
  const [netWeight, setNetWeight] = useState();
  const [tareWeight, setTareWeight] = useState();
  const [genPdfs, setGenPdfs] = useState([]);
  const [prodLabelsDetails, setProdLabelsDetails] = useState([
    { container_id: '', tare_wt: '', net_Wt: '', gross_weight: '' }
  ]);
  const [prodLabels, setProdLabels] = useState([]);
  const [dataId, setDataId] = useState();
  const [containerId, setContainerId] = useState('');
  const [isSubmit, setIsSubmit] = useState(false);
  const [stageData, setStageData] = useState([]);
  const [nextStageData, setNextStageData] = useState([]);

  const { user } = getLoggedInUser();
  const searchdata = useLocation().search;
  const params = new URLSearchParams(searchdata);
  useEffect(() => {
    handleGetProductBatches();
    handleGetProcessGroups();
    handleGetDispenseBooths();
    // handleGetProductLabels();
  }, [search]);

  console.log(setProdLabelsDetails, prodLabelsDetails);
  useEffect(() => {
    handleGetProductLabelsById();
  }, [genPdfs]);

  const handleGetProductBatches = () => {
    const payload = { search };
    dispatch(getProductBatches(payload)).then((res) => {
      if (res.payload.success) {
        setProductsData(res.payload.data);
      }
    });
  };

  // const handleGetProductLabels = () => {
  //   const payload = { search };
  //   dispatch(getProductBatches(payload)).then((res) => {
  //     if (res.payload.success) {
  //       setProductsData(res.payload.data);
  //     }
  //   });
  // };

  const handleGetProcessGroups = () => {
    const payload = { search };
    dispatch(getProcesses(payload)).then((res) => {
      if (res.payload.success) {
        setProcesses(res.payload.data);
      }
    });
  };

  const handleGetDispenseBooths = () => {
    dispatch(getAllDispenseBooths()).then((result) => {
      if (result?.payload?.success) {
        setDispenseBooths(result?.payload?.data);
      }
    });
  };

  const handleGetProductLabelsById = () => {
    const payload = { id: data?.id || params.get('id') };
    dispatch(getProducts(payload)).then((result) => {
      if (result?.payload?.success) {
        setProdLabels(result?.payload?.data?.[0]?.prod_dispensing_labels);
        params.get('id') &&
          form.setFieldsValue({
            dispensing_request_id:
              result?.payload?.data?.[0]?.dispensing_request_id,
            product_name: result?.payload?.data?.[0]?.product_detail?.name,
            bom_process_group_id:
              result?.payload?.data?.[0]?.bom_process_group_id,
            bom_process_id:
              result?.payload?.data?.[0]?.bom_process_master?.process_name,
            no_of_containers: result?.payload?.data?.[0]?.no_of_containers
          });
        setDataId(result.payload.data);
        setShowNextCard(true);
      }
    });
  };

  const onFinish = (values) => {
    console.log('Success:', values);
    dispatch(createProducts(values)).then((res) => {
      if (res.payload.success) {
        form.setFieldsValue({
          dispensing_request_id: values?.dispensing_request_id,
          product_name: values?.product_name,
          bom_process_group_id: values.bom_process_group_id,
          bom_process_id: values.bom_process_id,
          no_of_containers: values.no_of_containers
        });
        setData(res.payload.dispensingRecord);
        setShowNextCard(true);
      }
    });
  };

  const onFinish1 = (values) => {
    console.log('Success:', data);
    setIsSubmit(true);
    if (containerId !== '') {
      values['dispense_label_id'] = containerId;
      values['id'] = params.get('id');
    } else {
      values['id'] = data?.id;
    }
    values['net_Wt'] = netWeight;
    values['tare_Wt'] = tareWeight;
    values['gross_weight'] = parseInt(netWeight) + parseInt(tareWeight);
    dispatch(createProducts(values)).then((res) => {
      if (res.payload.success) {
        setIsSubmit(false);
        setGenPdfs([...genPdfs, { pdf: res.payload.data }]);
        setTareWeight('');
        setNetWeight('');
        console.log('Success:', res.payload.data);
      }
    });
  };
  const onSearch = (e) => {
    console.log(e);
    setSearch(e);
  };

  // const handleChange = (e) => {
  //   const processes = processGroups.filter((process) => process.id === e);
  //   setProcessDetails(processes?.[0]?.bom_process_masters);
  // };

  const onChange = (e) => {
    const product_name = productsData?.filter((product) => product.id === e);
    console.log(product_name);
    form?.setFieldsValue({
      product_name: product_name?.[0]?.product_detail?.name
    });
  };

  const onChangeprocess = (e) => {
    const process = processes?.filter((process) => process.id === e);
    console.log(process, 'process');
    setStageData(process?.[0]?.prod_label_stage_masters);
  };

  const onChangestage = (e) => {
    const stage = stageData?.filter((process) => process.id === e);
    setNextStageData(stage?.[0]?.prodlabel_nextstage_masters);
  };

  const handleEdit = (id) => {
    const list = [...prodLabels];
    const itemIndex = list.findIndex((item) => item.id === id);
    if (itemIndex !== -1) {
      list[itemIndex] = {
        ...list[itemIndex],
        tare_Wt: '',
        net_Wt: ''
      };
    }
    setProdLabels(list);
    setContainerId(id);
  };
  // const handleChangeWeight = (e, type) => {
  //   const list = [...productsData];
  //   list[type] = e.target.value;
  //   setProductsData(list);
  // };
  console.log(dataId);
  const weighing_balance_id = params.get('id')
    ? dispenseBooths?.filter(
        (item) => item.ip_address === prodLabels?.[0]?.weighing_balance
      )
    : '';

  console.log(weighing_balance_id);
  return (
    <>
      <Layout sider={false}>
        <Card
          title="Product Details"
          className={`${styles.cardStyles_approver}`}
        >
          <Form form={form} layout="vertical" onFinish={onFinish}>
            <Row gutter={24}>
              <Col span={8}>
                <FormItem
                  label="Product Batch #"
                  name="dispensing_request_id"
                  rules={[
                    {
                      required: true,
                      message: 'Select Product Batch'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Select
                    placeholder="Select Product Batch"
                    showSearch
                    optionFilterProp="children"
                    onChange={onChange}
                    onSearch={onSearch}
                    disabled={params.get('id') ? dataId : data}
                  >
                    {productsData?.map((category, index) => {
                      return (
                        <option value={category?.id} key={index}>
                          {category?.product_batch_number}
                        </option>
                      );
                    })}
                  </Select>
                </FormItem>
              </Col>
              <Col span={16}>
                <FormItem
                  label="Product name"
                  name="product_name"
                  rules={[
                    {
                      required: true,
                      message: 'Select Product name'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Input
                    placeholder=""
                    value=""
                    // disabled={params.get('id') ? dataId : data}
                    disabled
                  />
                </FormItem>
              </Col>
              {/* <Col span={8}>
                <FormItem
                  label="Process Group"
                  name="bom_process_group_id"
                  rules={[
                    {
                      required: true,
                      message: 'Select Product Batch'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Select
                    placeholder="Select Process group"
                    onChange={(e) => handleChange(e)}
                    disabled={params.get('id') ? dataId : data}
                  >
                    {processGroups?.map((category, index) => {
                      return (
                        <option value={category?.id} key={index}>
                          {category?.process_group_name}
                        </option>
                      );
                    })}
                  </Select>
                </FormItem>
              </Col> */}
              <Col span={6}>
                <FormItem
                  label="Process"
                  name="prodlabel_process_id"
                  rules={[
                    {
                      required: true,
                      message: 'Select Product Batch'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Select
                    placeholder="Select Process"
                    disabled={params.get('id') ? dataId : data}
                    showSearch
                    optionFilterProp="children"
                    onChange={onChangeprocess}
                    onSearch={onSearch}
                  >
                    {processes?.map((category, index) => {
                      return (
                        <option value={category?.id} key={index}>
                          {category?.process_label_name}
                        </option>
                      );
                    })}
                  </Select>
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem
                  label="Stages"
                  name="prodlabel_stage_id"
                  rules={[
                    {
                      required: true,
                      message: 'Select Product Batch'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Select
                    placeholder="Select Stage"
                    disabled={params.get('id') ? dataId : data}
                    showSearch
                    optionFilterProp="children"
                    onChange={onChangestage}
                    onSearch={onSearch}
                  >
                    {stageData?.map((category, index) => {
                      return (
                        <option value={category?.id} key={index}>
                          {category?.prodlabel_stage_name}
                        </option>
                      );
                    })}
                  </Select>
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem
                  label="Next Stage"
                  name="prodlabel_nextstage_id"
                  rules={[
                    {
                      required: false,
                      message: 'Select Product Batch'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Select
                    placeholder="Select Next Stage"
                    disabled={params.get('id') ? dataId : data}
                    showSearch
                    optionFilterProp="children"
                    // onChange={onChangestage}
                    onSearch={onSearch}
                  >
                    {nextStageData?.map((category, index) => {
                      return (
                        <option value={category?.id} key={index}>
                          {category?.prodlabel_nextstage_name}
                        </option>
                      );
                    })}
                  </Select>
                </FormItem>
              </Col>

              <Col span={6}>
                <FormItem
                  label="No of Containers"
                  name="no_of_containers"
                  rules={[
                    {
                      required: true,
                      message: 'Select Product Batch'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Select
                    placeholder="Select No of Containers"
                    disabled={params.get('id') ? dataId : data}
                    showSearch
                  >
                    {Array.from({ length: 101 }, (_, i) => i).map((i) => {
                      return (
                        <option value={i} key={i}>
                          {i}
                        </option>
                      );
                    })}
                  </Select>
                </FormItem>
              </Col>
            </Row>
            {params.get('id')
              ? !dataId
              : !data && (
                  <FormItem className="text-center">
                    <Space>
                      <CustomButton
                        className={styles.inwardButton}
                        htmlType="submit"
                        type="primary"
                      >
                        Proceed to Weighing
                      </CustomButton>
                      <CustomButton
                        className={styles.inwardButton}
                        type="primary"
                        ghost
                        onClick={() => history.back()}
                      >
                        Back
                      </CustomButton>
                    </Space>
                  </FormItem>
                )}
          </Form>
        </Card>
        {showNextCard && (
          <Card
            title="Weighing Details"
            className={`${styles.cardStyles_approver}`}
          >
            <Form form={form1} layout="vertical" onFinish={onFinish1}>
              <Row gutter={24}>
                <Col span={8}>
                  {params.get('id') ? (
                    <FormItem
                      label="Weighing Balance"
                      rules={[
                        {
                          required: true,
                          message: 'Select Product Batch'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Input
                        disabled
                        value={weighing_balance_id?.[0]?.balance_name}
                      />
                    </FormItem>
                  ) : (
                    <FormItem
                      label="Weighing Balance"
                      name="weighing_balance"
                      rules={[
                        {
                          required: true,
                          message: 'Select Product Batch'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Select placeholder="Select Weighing Balance">
                        {dispenseBooths?.map((item, index) => {
                          return (
                            <option value={item?.ip_address} key={index}>
                              {item?.balance_name}
                            </option>
                          );
                        })}
                      </Select>
                    </FormItem>
                  )}
                </Col>
                <Col span={8}>
                  <FormItem
                    label="Performed By"
                    rules={[
                      {
                        required: false,
                        message: 'Select Product name'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <Input
                      placeholder=""
                      value={`${user?.employee} ( ${moment().format(
                        'YYYY-MM-DD'
                      )})`}
                      disabled
                    />
                  </FormItem>
                </Col>
              </Row>
              {/* {Array.from({ length: 5 }, (_, i) => i).map((i) => {
                return ( */}
              {prodLabels?.length > 0
                ? prodLabels?.map((item, index) => {
                    return (
                      <Row gutter={24} key={index}>
                        <Col span={5}>
                          <FormItem
                            label="Container ID"
                            className={styles.customLable}
                          >
                            <Input
                              placeholder=""
                              value={item?.prod_containers}
                              disabled
                            />
                          </FormItem>
                        </Col>
                        <Col span={5}>
                          <FormItem
                            label="Tare Weight"
                            rules={[
                              {
                                required: true,
                                message: 'Select Product Batch'
                              }
                            ]}
                            className={styles.customLable}
                          >
                            {!item?.tare_Wt ? (
                              <Input
                                placeholder="Tare Weight"
                                onChange={(e) => setTareWeight(e.target.value)}
                              />
                            ) : (
                              <Input
                                placeholder="Tare Weight"
                                value={item?.tare_Wt}
                                onChange={(e) => setTareWeight(e.target.value)}
                                disabled={item?.tare_Wt}
                              />
                            )}
                          </FormItem>
                        </Col>
                        <Col span={5}>
                          <FormItem
                            label="Net Weight"
                            rules={[
                              {
                                required: true,
                                message: 'Select Product Batch'
                              }
                            ]}
                            className={styles.customLable}
                          >
                            {!item?.tare_Wt ? (
                              <Input
                                placeholder="Net Weight"
                                onChange={(e) => setNetWeight(e.target.value)}
                              />
                            ) : (
                              <Input
                                placeholder="Net Weight"
                                value={item?.net_Wt}
                                onChange={(e) => setNetWeight(e.target.value)}
                                disabled={item?.tare_Wt}
                              />
                            )}
                          </FormItem>
                        </Col>
                        <Col span={5}>
                          <FormItem
                            label="Gross Weight"
                            // name="gross_weight"
                            rules={[
                              {
                                required: false,
                                message: 'Select Product Batch'
                              }
                            ]}
                            className={styles.customLable}
                          >
                            <Input
                              placeholder="Gross Weight"
                              value={
                                parseInt(item?.tare_Wt || 0) +
                                  parseInt(item?.net_Wt || 0) ||
                                parseInt(tareWeight) + parseInt(netWeight)
                              }
                              disabled
                            />
                          </FormItem>
                        </Col>

                        <Col span={4}>
                          <FormItem
                            className="text-center"
                            style={{ marginTop: '28px' }}
                          >
                            {!item?.tare_Wt ? (
                              <CustomButton
                                className={styles.inwardButton}
                                htmlType="submit"
                                type="primary"
                              >
                                Print & Add
                              </CustomButton>
                            ) : (
                              <Space>
                                <CustomButton
                                  className={styles.inwardButton}
                                  type="primary"
                                  onClick={() =>
                                    window.open(item?.doc_url, '_blank')
                                  }
                                >
                                  <CloudDownloadOutlined />
                                </CustomButton>
                                {params.get('id') && (
                                  <CustomButton
                                    className={styles.inwardButton}
                                    type="primary"
                                    onClick={() => handleEdit(item.id)}
                                  >
                                    <EditOutlined />
                                  </CustomButton>
                                )}
                              </Space>
                            )}
                          </FormItem>
                        </Col>
                      </Row>
                    );
                  })
                : ''}
              {console.log(prodLabels?.length, data?.no_of_containers)}
              {prodLabels?.length < data?.no_of_containers ? (
                <Row gutter={24}>
                  <Col span={5}>
                    <FormItem
                      label="Container ID"
                      name=""
                      rules={[
                        {
                          required: false,
                          message: 'Select Product Batch'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Input placeholder="" value="" disabled />
                    </FormItem>
                  </Col>
                  <Col span={5}>
                    <FormItem
                      label="Tare Weight"
                      // name="tare_Wt"
                      rules={[
                        {
                          required: true,
                          message: 'Select Product Batch'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Input
                        placeholder="Tare Weight"
                        value={tareWeight}
                        onChange={(e) => setTareWeight(e.target.value)}
                      />
                    </FormItem>
                  </Col>
                  <Col span={5}>
                    <FormItem
                      label="Net Weight"
                      // name="net_Wt"
                      rules={[
                        {
                          required: true,
                          message: 'Select Product Batch'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Input
                        placeholder="Net Weight"
                        value={netWeight}
                        onChange={(e) => setNetWeight(e.target.value)}
                      />
                    </FormItem>
                  </Col>
                  <Col span={5}>
                    <FormItem
                      label="Gross Weight"
                      // name="gross_weight"
                      rules={[
                        {
                          required: false,
                          message: 'Select Product Batch'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Input
                        placeholder="Gross Weight"
                        value={
                          parseInt(netWeight || 0) + parseInt(tareWeight || 0)
                        }
                        disabled
                      />
                    </FormItem>
                  </Col>
                  <Col span={4}>
                    <FormItem
                      className="text-center"
                      style={{ marginTop: '28px' }}
                    >
                      <Space>
                        {isSubmit ? (
                          <Spin />
                        ) : (
                          <CustomButton
                            className={styles.inwardButton}
                            htmlType="submit"
                            type="primary"
                          >
                            Print & Add
                          </CustomButton>
                        )}
                      </Space>
                    </FormItem>
                  </Col>
                </Row>
              ) : (
                ''
              )}
              {/* <Col span={4}> */}
              <FormItem className="text-center" style={{ marginTop: '28px' }}>
                <CustomButton
                  className={styles.inwardButton}
                  onClick={() => history.back()}
                  type="primary"
                >
                  Back
                </CustomButton>
              </FormItem>
              {/* </Col> */}
              {/* );
              })} */}
            </Form>
          </Card>
        )}
      </Layout>
    </>
  );
};
export default CreateProductLabel;
