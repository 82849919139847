import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Form,
  Card,
  Radio,
  Space,
  Select,
  Input,
  Collapse,
  message,
  Image,
  InputNumber,
  // Tooltip,
  Popconfirm,
  Tooltip
  // Button
} from 'antd';
import styles from './index.module.less';
import FormItem from '@/common/FormItem';
import 'react-quill/dist/quill.snow.css';
import CustomButton from '@/common/CustomButton';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '@/common/Loader';
import {
  getAllMaterialsBatches,
  createDispensingSheet,
  getContainers,
  getDispenseMaterialDetails,
  createprintIssue,
  updateprintIssue,
  getDispenseBMRDetails,
  updateRequestStatus,
  recalculateYield,
  getAllDispenseBooths,
  getBalance,
  getAllContainers,
  getstageName,
  stockDeduct
} from '../../redux/slice';
import Layout from '@/common/Layout';
const { Panel } = Collapse;
import { QrReader } from 'react-qr-reader';
import {
  QrcodeOutlined,
  PlusOutlined,
  CloseOutlined,
  EditOutlined,
  CloudDownloadOutlined,
  CheckCircleOutlined
  // DownOutlined
} from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import { history } from 'app/history';
import moment from 'moment';
import { getLoggedInUser, getRoles } from 'utilities/helpers';
import truck from './images/truck-delivery-done.gif';
import { Skeleton } from 'antd';
import CustomModal from '@/common/CustomModal';
import VerificationModal from '@/features/material/components/VerificationModal';
import DeleteMaterialsModel from '../DeleteMaterialsModel';
// import { groupBy } from 'lodash';

const DispensingMaterialPage = ({ loading }) => {
  const { allMaterials, allContainers, materialDetails } = useSelector(
    (state) => state.dispensing
  );
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const params = useParams();
  const { user } = getLoggedInUser();
  const { Superadmindata } = getRoles('Warehouse');

  const [type, setType] = useState('');
  const [code, setCode] = useState('');
  const [search, setSearch] = useState('');
  const [openCam, setOpenCam] = useState(false);
  const [matContainers, setMatContainers] = useState([]);
  const [counter, setCounter] = useState(1);
  const [data, setData] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [materialsLength, setMaterialsLength] = useState(0);
  const [bmrcount, setBmrcount] = useState(0);
  const [newMaterials, setNewMaterials] = useState([]);
  const [dispenseMat, setDispenseMat] = useState([]);
  const [extraDispenseMat, setExtraDispenseMat] = useState([]);
  const [extragranuler, setExtragranuler] = useState(false);
  const [selectSecondValue, setSelectSecondValue] = useState([]);
  const [totalNet, setTotalNet] = useState(0);
  const [anotherBatch, setAnotherBatch] = useState(1);
  const [productType, setProductType] = useState('');
  const [materials, setMaterials] = useState([]);
  const [netTotal, setNetTotal] = useState(0);
  const [netNextQty, setNetNextQty] = useState(0);
  const [showIcon, setShowIcon] = useState(false);
  const [skeltonActive, setSkeltonActive] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [yieldQty, setYieldQty] = useState('');
  const [stageGroup, setStageGroup] = useState('');
  const [dispenseBooths, setDispenseBooths] = useState([]);
  const [ipAddress, setIpAddress] = useState();
  const [matId, setMatId] = useState('');
  const [containersData, setContainersData] = useState([]);
  const [dispenseData, setDispenseData] = useState([]);
  const [stageName, setStageName] = useState('');
  const [balanceName, setBalanceName] = useState('');
  const [balanceCode, setBalanceCode] = useState('');
  const [dispenseMaterial, setDispenseMaterial] = useState();
  const [isModal, setIsModal] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [stockMatID, setStockMatID] = useState([]);
  const [stockBatches, setStockBatches] = useState([]);
  const [isVerify, setIsVerify] = useState(false);
  const [newStockBatches, setNewStockBatches] = useState([]);
  const [isConfirm, setIsConfirm] = useState(false);
  const [dispenseanother, setDispenseanother] = useState(false);
  const [newMaterialsdata, setNewMaterialsdata] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  console.log(dispenseBooths, ipAddress);
  console.log(totalNet, setExtragranuler, extraDispenseMat, setAnotherBatch);
  console.log(netTotal);

  const d = new Date();
  let year = d.getFullYear();
  var twoDigitYear = year.toString().substr(-2);
  console.log(twoDigitYear, 'twoDigitYear');

  const list = [];

  for (let i = 1; i <= 30; i++) {
    list.push(i);
  }

  const handleStageGroup = (e) => {
    setStageGroup(e);
    let payload = { dispensing_request_id: params.id, stage_group: e };
    dispatch(getstageName(payload)).then((response) => {
      if (response?.payload?.success) {
        setStageName(response?.payload?.data?.stage_name);
      }
    });
  };

  const handleYieldChange = (e) => {
    setYieldQty(e.target.value);
  };
  const handleaddBMRMaterial = () => {
    console.log(bmrcount, 'bmrcount');
    setBmrcount(bmrcount + 1);
    setSkeltonActive(true);
    form.resetFields();
    form.setFieldsValue({});
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }, 200);
    setNewMaterials([...newMaterials, materials]);
    setSelectSecondValue([]);
    // dispenseSheet();
  };
  console.log(materials);
  useEffect(() => {
    handleGetAllMaterialsList();
  }, [search, code, materials]);

  const handleGetAllMaterialsList = () => {
    let payload = { search };
    dispatch(getAllMaterialsBatches(payload));
  };

  useEffect(() => {
    handleGetAllContainers();
  }, [search]);

  const handleGetAllContainers = () => {
    let payload = { material_id: matId, search: search };
    payload.material_id
      ? dispatch(getAllContainers(payload)).then((response) => {
          console.log(response.payload.data, 'llll');
          setContainersData(response.payload.data);
        })
      : '';
  };

  const handleSubmit = () => {
    const payload = {
      dispensing_request_id: params.id,
      yield_value: yieldQty,
      stage_group: stageGroup
    };
    dispatch(recalculateYield(payload)).then((response) => {
      if (response.payload.success) {
        message.success(response.payload.message);
        history.push('/dispense-material-list');
      }
    });
  };
  useEffect(() => {
    handleGetAllMaterials();
  }, [bmrcount]);

  useEffect(() => {
    handleGetDispenseBooths();
  }, []);

  const handleGetDispenseBooths = () => {
    dispatch(getAllDispenseBooths()).then((result) => {
      if (result?.payload?.success) {
        setDispenseBooths(result?.payload?.data);
      }
    });
  };
  const handleGetAllMaterials = () => {
    let payload = {
      id: params.id,
      per_page: 10
    };
    dispatch(getDispenseMaterialDetails(payload)).then(function (result) {
      if (result?.payload?.success) {
        setType(result?.payload?.data?.type);
        setProductType(result?.payload?.data?.product_detail?.is_product);
        if (result?.payload?.data?.type == 'bmr') {
          payload['type'] = 'bmr';
          dispatch(getDispenseBMRDetails(payload)).then(function (result) {
            if (result?.payload?.success) {
              setSkeltonActive(false);
              setDispenseData(
                result?.payload?.data?.product_detail.bmr_product_materials
              );
              result?.payload?.data?.materials?.length
                ? setCounter(result?.payload?.data?.materials?.length)
                : '';
              let material_ids = [];

              let disp_materials =
                result?.payload?.data?.product_detail.bmr_product_materials;
              const propComparator = (propName) => (a, b) =>
                a[propName] == b[propName]
                  ? 0
                  : a[propName] < b[propName]
                  ? -1
                  : 1;

              disp_materials.sort(propComparator('dispensing_sequence'));
              disp_materials.map((item) => {
                if (material_ids.indexOf(item.dispensing_sequence) === -1) {
                  material_ids.push(item.dispensing_sequence);
                }
              });
              var dispense_materials = [];
              var extra_dispense_materials = [];
              material_ids?.map((param) => {
                console.log(param);
                const lot =
                  result?.payload?.data?.product_detail.bmr_product_materials.filter(
                    // (item) => item.material_id == param && !item.is_extra
                    (item) =>
                      parseInt(item.dispensing_sequence) == parseInt(param)
                    // &&
                    // !item.is_extra
                  );
                // const extra_lot =
                //   result?.payload?.data?.product_detail.bmr_product_materials.filter(
                //     (item) =>
                //       parseInt(item.dispensing_sequence) == parseInt(param) &&
                //       item.is_extra
                //   );
                lot.length ? dispense_materials.push(lot) : '';
                // extra_lot.length
                //   ? extra_dispense_materials.push(extra_lot)
                //   : '';
              });
              setNewMaterialsdata(
                result?.payload?.data?.product_detail.bmr_product_materials
              );
              setMaterialsLength(dispense_materials?.length);

              setExtraDispenseMat(extra_dispense_materials);
              // var materials_new_data = extragranuler
              //   ? extra_dispense_materials
              //   : dispense_materials;
              var materials_new_data = [
                ...dispense_materials
                // ...extra_dispense_materials
              ];
              setDispenseMat(materials_new_data);
              console.log(materials_new_data);
              let final_materials = [];
              let remove_dup = [];
              materials_new_data.map((item) => {
                remove_dup.push([item[0]]);
              });
              console.log(remove_dup, 'remove_dup');
              // setMaterials(
              console.log(materials_new_data);
              remove_dup
                .filter((e) => e.length)
                [bmrcount]?.map((item) => {
                  const materials = allMaterials?.rows?.filter(
                    (allMaterials) => allMaterials.name == item?.material?.name
                  );
                  console.log(item?.material?.name, 'material_name');
                  item?.is_part_exist && !item?.is_extra
                    ? item?.material_lots?.map((lot_item, index) => {
                        lot_item?.material_parts?.map((part_item, i) => {
                          let sub_batches =
                            lot_item?.material?.dispense_sub_batches.filter(
                              (part) => part.material_part_id == part_item?.id
                            );
                          let batches = [];
                          sub_batches?.length
                            ? sub_batches?.map((item) => {
                                let new_dis = [];
                                console.log(
                                  lot_item?.material?.new_dispensary_containers
                                );
                                console.log(lot_item.id, 'lot_item_id');
                                let dispense_cont =
                                  lot_item?.material?.new_dispensary_containers?.filter(
                                    (new_dis) =>
                                      new_dis.batch_details_id ==
                                        item.batch_details_id &&
                                      new_dis.material_lot_id == lot_item.id &&
                                      new_dis.material_part_id == part_item?.id
                                  );
                                dispense_cont.map((dis) => {
                                  new_dis.push({
                                    dispense_pdf_id:
                                      dis?.dispensing_requests_pdf_id,
                                    dispense_container_id: dis.id,
                                    dispensing_container: dis.dc_number,
                                    tare_weight: parseFloat(
                                      dis.tare_weight.replace(/[^\d.-]/g, '')
                                    ).toFixed(3),
                                    net_weight: parseFloat(
                                      dis.net_weight.replace(/[^\d.-]/g, '')
                                    ).toFixed(3),
                                    gross_weight: parseFloat(
                                      dis.gross_weight.replace(/[^\d.-]/g, '')
                                    ).toFixed(3),
                                    max_net_weight: parseFloat(
                                      dis.net_weight.replace(/[^\d.-]/g, '')
                                    ).toFixed(3)
                                  });
                                });

                                let new_dis_containers = [];

                                sub_batches.map((item) => {
                                  new_dis_containers.push({
                                    id: item?.id,
                                    warehouse_container:
                                      item?.warehouse_container,
                                    batch_number: item?.batch_number,
                                    lot_no: item?.lot_no,
                                    total_net_weight: item?.total_net_weight,
                                    actual_quantity: item?.actual_quantity,
                                    dispense_qty: item?.dispense_qty,
                                    is_left_over_container:
                                      item?.is_left_over_container,
                                    is_active: item?.is_active,
                                    dispensing_request_id:
                                      item?.dispensing_request_id,
                                    batch_id: item?.batch_details_id,
                                    dispensing_material_id:
                                      item?.dispensing_material_id,
                                    container_id: item?.container_id,
                                    material_id: item?.material_id,
                                    material_lot_id: item?.material_lot_id,
                                    material_part_id: item?.material_part_id
                                  });
                                });

                                batches.push({
                                  dispenses: new_dis,
                                  sub_batches: new_dis_containers
                                });
                              })
                            : batches.push({
                                dispenses: [
                                  {
                                    dispensing_container: `DC-${twoDigitYear}-0001`,
                                    tare_weight: '',
                                    net_weight: '',
                                    gross_weight: 0,
                                    max_net_weight: 0
                                  }
                                ],
                                sub_batches: []
                              });
                          final_materials.push({
                            title: `LOT ${index + 1} - PART ${i + 1}`,
                            lot_number: lot_item?.lot_no + part_item?.part_no,
                            lot_no: lot_item?.lot_no,
                            part_no: part_item?.part_no,
                            is_part_exist: item?.is_part_exist,
                            id: item?.id,
                            part_index: i,
                            is_drafted: true,
                            dispensing_request_id: params.id,
                            material_code: item?.material?.code,
                            material_id: item?.material?.id,
                            material_name: item?.material?.name,
                            materials_list: materials,
                            uom: item?.material?.is_conversion
                              ? 'KG'
                              : item?.material?.uom,
                            is_conversion: item?.material?.is_conversion,
                            lot: part_item?.part_value,
                            actual_quantity: sub_batches?.[0]?.actual_quantity
                              ? sub_batches?.[0]?.actual_quantity
                              : '',
                            total_net_weight: sub_batches?.[0]?.total_net_weight
                              ? sub_batches?.[0]?.total_net_weight
                              : '',
                            product_type: item?.material?.item,
                            is_extra: item?.is_extra,
                            material_part_id: part_item?.id,
                            material_lot_id: lot_item?.id,
                            dispense_containers: sub_batches?.length
                              ? true
                              : false,
                            batches: batches,
                            pdf_url:
                              lot_item?.material?.dispensing_request_pdfs?.[i]
                                ?.pdf_doc,
                            is_stock_deduct:
                              lot_item?.material?.dispensing_request_pdfs?.[i]
                                ?.is_stock_deduct,
                            pdf_id:
                              lot_item?.material?.dispensing_request_pdfs?.[i]
                                ?.id,
                            prod_user_id:
                              lot_item?.material?.dispensing_request_pdfs?.[i]
                                ?.user_id,
                            dispense_history:
                              result?.payload?.data?.dispensing_histories,
                            material_sequences: item?.material_sequence
                          });
                        });
                      })
                    : !item?.is_part_exist && !item?.is_extra
                    ? item?.material_lots?.map((lot_item, index) => {
                        let sub_batches =
                          lot_item?.material?.[0]?.dispense_sub_batches.filter(
                            (lot) => lot.material_lot_id == lot_item?.id
                          );
                        let batches = [];
                        sub_batches?.length
                          ? sub_batches?.map((item) => {
                              let new_dis = [];
                              console.log(
                                lot_item?.material?.[0]
                                  ?.new_dispensary_containers
                              );
                              let dispense_cont =
                                lot_item?.material?.[0]?.new_dispensary_containers?.filter(
                                  (new_dis) =>
                                    new_dis.batch_details_id ==
                                      item.batch_details_id &&
                                    new_dis.material_lot_id == lot_item.id
                                );
                              dispense_cont.map((dis) => {
                                new_dis.push({
                                  dispense_pdf_id:
                                    dis?.dispensing_requests_pdf_id,
                                  dispense_container_id: dis.id,
                                  dispensing_container: dis.dc_number,
                                  tare_weight: parseFloat(
                                    dis.tare_weight.replace(/[^\d.-]/g, '')
                                  ).toFixed(3),
                                  net_weight: parseFloat(
                                    dis.net_weight.replace(/[^\d.-]/g, '')
                                  ).toFixed(3),
                                  gross_weight: parseFloat(
                                    dis.gross_weight.replace(/[^\d.-]/g, '')
                                  ).toFixed(3),
                                  max_net_weight: parseFloat(
                                    dis.net_weight.replace(/[^\d.-]/g, '')
                                  ).toFixed(3)
                                });
                              });

                              let new_dis_containers = [];

                              sub_batches.map((item) => {
                                new_dis_containers.push({
                                  id: item?.id,
                                  warehouse_container:
                                    item?.warehouse_container,
                                  batch_number: item?.batch_number,
                                  lot_no: item?.lot_no,
                                  total_net_weight: item?.total_net_weight,
                                  actual_quantity: item?.actual_quantity,
                                  dispense_qty: item?.dispense_qty,
                                  is_left_over_container:
                                    item?.is_left_over_container,
                                  is_active: item?.is_active,
                                  dispensing_request_id:
                                    item?.dispensing_request_id,
                                  batch_id: item?.batch_details_id,
                                  dispensing_material_id:
                                    item?.dispensing_material_id,
                                  container_id: item?.container_id,
                                  material_id: item?.material_id,
                                  material_lot_id: item?.material_lot_id,
                                  material_part_id: item?.material_part_id
                                });
                              });

                              batches.push({
                                dispenses: new_dis,
                                sub_batches: new_dis_containers
                              });
                            })
                          : batches.push({
                              dispenses: [
                                {
                                  dispensing_container: `DC-${twoDigitYear}-0001`,
                                  tare_weight: '',
                                  net_weight: '',
                                  gross_weight: 0,
                                  max_net_weight: 0
                                }
                              ],
                              sub_batches: []
                            });
                        final_materials.push({
                          title: `LOT ${index + 1}`,
                          lot_number: lot_item?.lot_no,
                          part_no: '',
                          lot_no: lot_item?.lot_no,
                          is_part_exist: item?.is_part_exist,
                          id: item?.id,
                          is_drafted: true,
                          dispensing_request_id: params.id,
                          material_code: item?.material?.code,
                          material_id: item?.material?.id,
                          material_name: item?.material?.name,
                          materials_list: materials,
                          material_lot_id: lot_item?.id,
                          uom: item?.material?.is_conversion
                            ? 'KG'
                            : item?.material?.uom,
                          is_conversion: item?.material?.is_conversion,
                          lot: lot_item?.lot_value,
                          actual_quantity: sub_batches?.[0]?.actual_quantity
                            ? sub_batches?.[0]?.actual_quantity
                            : '',
                          total_net_weight: sub_batches?.[0]?.total_net_weight
                            ? sub_batches?.[0]?.total_net_weight
                            : '',
                          product_type: item?.material?.item,
                          is_extra: item?.is_extra,
                          dispense_containers: sub_batches?.length
                            ? true
                            : false,
                          batches: batches,
                          pdf_url:
                            lot_item?.material?.[0]
                              ?.dispensing_request_pdfs?.[0]?.pdf_doc,
                          is_stock_deduct:
                            lot_item?.material?.[0]
                              ?.dispensing_request_pdfs?.[0]?.is_stock_deduct,
                          pdf_id:
                            lot_item?.material?.[0]
                              ?.dispensing_request_pdfs?.[0]?.id,
                          prod_user_id:
                            lot_item?.material?.[0]
                              ?.dispensing_request_pdfs?.[0]?.user_id,
                          dispense_history:
                            result?.payload?.data?.dispensing_histories,
                          material_sequences: item?.material_sequence
                        });
                      })
                    : item?.bmrMaterialLots.map((bmr_mat) => {
                        console.log(bmr_mat);
                        let batches = [];
                        bmr_mat.dispense_sub_batches?.length
                          ? bmr_mat.dispense_sub_batches.map((dis) => {
                              let dis_cont =
                                bmr_mat.new_dispensary_containers.filter(
                                  (dis_cont) =>
                                    dis_cont.batch_details_id ==
                                    dis.batch_details_id
                                );
                              let new_dis = [];
                              dis_cont.map((dis) => {
                                new_dis.push({
                                  dispense_pdf_id:
                                    dis?.dispensing_requests_pdf_id,
                                  dispense_container_id: dis.id,
                                  dispensing_container: dis.dc_number,
                                  tare_weight: parseFloat(
                                    dis.tare_weight.replace(/[^\d.-]/g, '')
                                  ).toFixed(3),
                                  net_weight: parseFloat(
                                    dis.net_weight.replace(/[^\d.-]/g, '')
                                  ).toFixed(3),
                                  gross_weight: parseFloat(
                                    dis.gross_weight.replace(/[^\d.-]/g, '')
                                  ).toFixed(3),
                                  max_net_weight: parseFloat(
                                    dis.net_weight.replace(/[^\d.-]/g, '')
                                  ).toFixed(3)
                                });
                              });
                              batches.push({
                                dispenses: new_dis,
                                sub_batches: [
                                  {
                                    actual_quantity: dis?.actual_quantity,
                                    batch_id: dis?.batch_details_id,
                                    batch_number: dis?.batch_number,
                                    container_id: dis?.container_id,
                                    dispense_qty: dis?.dispense_qty,
                                    dispensing_material_id:
                                      dis?.dispensing_material_id,
                                    dispensing_request_id:
                                      dis?.dispensing_request_id,
                                    id: dis?.id,
                                    is_active: dis?.is_active,
                                    is_left_over_container:
                                      dis?.is_left_over_container,
                                    lot_no: dis?.lot_no,
                                    material_id: dis?.material_id,
                                    material_lot_id: dis?.material_lot_id,
                                    material_part_id: dis?.material_part_id,
                                    total_net_weight: dis?.total_net_weight,
                                    warehouse_container:
                                      dis?.warehouse_container
                                  }
                                ]
                              });
                            })
                          : batches.push({
                              dispenses: [
                                {
                                  dispensing_container: `DC-${twoDigitYear}-0001`,
                                  tare_weight: '',
                                  net_weight: '',
                                  gross_weight: 0,
                                  max_net_weight: 0
                                }
                              ],
                              sub_batches: []
                            });

                        final_materials.push({
                          title: `dispense_qty`,
                          lot_number: 'dispense_qty',
                          id: item?.id,
                          is_drafted: true,
                          dispensing_request_id: params.id,
                          material_code: item?.material?.code,
                          material_id: item?.material?.id,
                          material_name: item?.material?.name,
                          materials_list: materials,
                          uom: item?.material?.is_conversion
                            ? 'KG'
                            : item?.material?.uom,
                          is_conversion: item?.material?.is_conversion,
                          lot: item?.quantity_for_batch,
                          actual_quantity: bmr_mat?.dispense_sub_batches?.[0]
                            ?.actual_quantity
                            ? bmr_mat?.dispense_sub_batches?.[0]
                                ?.actual_quantity
                            : '',
                          total_net_weight: bmr_mat?.dispense_sub_batches?.[0]
                            ?.total_net_weight
                            ? bmr_mat?.dispense_sub_batches?.[0]
                                ?.total_net_weight
                            : '',
                          product_type: bmr_mat?.item,
                          is_extra: item?.is_extra,
                          batches: batches,
                          dispense_containers: batches?.[0]?.sub_batches?.length
                            ? true
                            : false,
                          pdf_url:
                            bmr_mat?.dispensing_request_pdfs?.[0]?.pdf_doc,
                          is_stock_deduct:
                            bmr_mat?.dispensing_request_pdfs?.[0]
                              ?.is_stock_deduct,
                          pdf_id: bmr_mat?.dispensing_request_pdfs?.[0]?.id,
                          prod_user_id:
                            bmr_mat?.dispensing_request_pdfs?.[0]?.user_id,
                          dispense_history:
                            result?.payload?.data?.dispensing_histories,
                          material_sequences: item?.material_sequence
                        });
                      });
                  setMaterials(final_materials);
                });
              // );
              console.log(materials, 'final_materials');
            }
          });
        } else if (result?.payload?.data?.dispensing_materials?.length) {
          setMatContainers(
            result?.payload?.data?.dispensing_materials?.map((item) => {
              return item?.batch_detail?.containers || [];
            })
          );
          result?.payload?.data?.dispensing_materials?.map(() => {
            setCounter(counter + 1);
          });
        }
      }
    });
  };
  const handleaddMaterial = () => {
    console.log(counter, 'counter');
    setCounter(counter + 1);
    setNewMaterials([...newMaterials, materials[counter]]);
  };
  console.log(materials, 'mats');
  const onSearch = (value, id) => {
    console.log(value, id, 'jjj');
    setSearch(value);
    setMatId(id);
  };

  console.log(materials);
  const onChange = (
    index,
    value,
    is_extra,
    lot1,
    is_part_exist,
    part_no,
    part_index
  ) => {
    if (selectSecondValue[index] !== '') {
      setSelectSecondValue({ ...selectSecondValue, [index]: value });
    } else {
      setSelectSecondValue([...selectSecondValue, value]);
    }
    const material = allMaterials?.rows.filter(
      (allMaterials) => allMaterials.id === value
    );
    const disp_hist = materials?.[0]?.dispense_history?.filter(
      (item) => item?.material_id == value
    );

    const lot =
      is_extra === true
        ? disp_hist?.filter((item) => item?.lot_no == lot1)
        : disp_hist?.filter((item) => item?.lot_no == lot1);

    let temp_batches = [...materials];
    const filter_data = dispenseData.filter(
      (dispense) => dispense.material_id === value
    );
    const lot_id = is_part_exist
      ? filter_data[0].material_lots.filter((lot) => lot.lot_no == part_no)
      : filter_data[0].material_lots.filter((lot) => lot.lot_no == lot1);
    console.log(
      filter_data[0].material_lots,
      lot_id,
      is_part_exist,
      part_no,
      'disp_his1t'
    );
    var add = 0;
    lot?.map((dis_item) => {
      add += parseFloat(dis_item?.dispensed_qty);
    });
    lot?.map((dis_item, lot_index) => {
      if (lot_index + 1 == anotherBatch) {
        temp_batches[index].batches[0].sub_batches.push({
          warehouse_container: '',
          is_left_over: 'No',
          batch_id: dis_item?.batch_details_id,
          batch_number: dis_item?.batch_number || '',
          dispense_qty: dis_item?.dispensed_qty || ''
        });

        setMaterials(temp_batches);
      }
    });
    let temp_materials = [...materials];
    temp_materials[index].actual_quantity = add;
    setMaterials(temp_materials);
    setCode(material[0]);
    form.setFieldsValue({
      material_name: material[0]?.name,
      material_code: material[0]?.code
    });
    const payload = { material_id: value };
    const list = [...materials];
    list[index]['material_code'] = material[0]?.code;
    list[index]['material_id'] = value;
    list[index]['material_lot_id'] = lot_id[0]?.id;
    list[index]['material_part_id'] =
      lot_id[0]?.material_parts?.[part_index]?.id || null;
    list[index]['id'] = lot_id[0]?.bmr_material;
    list[index]['material_name'] = material[0]?.name;
    list[index]['uom'] = material[0]?.is_conversion ? 'KG' : material[0]?.uom;
    list[index]['product_name'] = material[0]?.product_detail?.name;
    list[index]['product_batch_number'] = material[0]?.product_batch_number;
    setMaterials(list);
    type == 'bmr'
      ? dispatch(getContainers(payload))
      : dispatch(getDispenseMaterialDetails(payload)).then(function (response) {
          if (response?.payload?.data?.dispensing_materials?.length) {
            setMatContainers(
              response?.payload?.data?.dispensing_materials?.map((item) => {
                return item?.batch_detail?.containers || [];
              })
            );
          }
        });
  };

  const onChangeWarehouse = (index, i, sub_i, value) => {
    const warehouse_containers =
      type == 'bmr'
        ? allContainers.filter((allContainers) => allContainers?.id == value)
        : matContainers[index].filter(
            (allContainers) => allContainers?.id == value
          );
    const container_list = [];
    containersData.map((item) => {
      item.containers.map((data) => {
        container_list.push(data);
      });
    });
    const list = [...materials];
    list[index]['batches'][i]['sub_batches'][sub_i]['warehouse_container'] =
      warehouse_containers[0]?.number;
    list[index]['batches'][i]['sub_batches'][sub_i]['container_id'] = value;
    {
      type == 'bmr'
        ? (list[index]['batches'][i]['sub_batches'][sub_i]['batch_id'] =
            warehouse_containers[0]?.batch_detail?.id)
        : '';
    }
    {
      type == 'bmr'
        ? (list[index]['batches'][i]['sub_batches'][sub_i]['batch_number'] =
            warehouse_containers[0]?.batch_detail?.inhouse_batch_number)
        : '';
    }
    setMaterials(list);
  };

  const onChangeleftover = (index, i, sub_i) => {
    materials[index]['batches'][i]['sub_batches'].map((_, j) => {
      const list = [...materials];
      j == sub_i
        ? (list[index]['batches'][i]['sub_batches'][j]['is_left_over'] = 'Yes')
        : (list[index]['batches'][i]['sub_batches'][j]['is_left_over'] = 'No');
      setMaterials(list);
    });
  };

  const handleBalance = (e) => {
    let booth_details = dispenseBooths.filter((item) => item?.ip_address == e);
    setIpAddress(e);
    setBalanceName(booth_details?.[0]?.balance_name);
    setBalanceCode(booth_details?.[0]?.dispense_booth_id);
  };
  const onFinish = () => {
    console.log(materials, 'llll');
    let a = newMaterials;
    let b = a.concat([materials]);
    // setBmrcount(bmrcount + 1);
    // setNewMaterials([...newMaterials, materials]);
    // asyncCall();
    // const myTimeout = setTimeout(dispenseSheet(), 5000);

    const payload = {
      dispense_materials: b
    };
    console.log(payload);
    dispatch(createDispensingSheet(payload)).then(function (response) {
      if (response?.payload?.success) {
        window.open(response?.payload?.data, '_blank');
        history.push(`/dispense-material-list`);
      }
    });
  };
  console.log(newMaterials, setShowIcon);
  // const dispenseSheet = () => {
  //   console.log(newMaterials);
  // };
  const handleError = (err) => {
    console.error(err);
  };

  const handleQrScan = async (index, i, sub_i, scanData) => {
    if (scanData && scanData !== '') {
      let Containers =
        type == 'bmr'
          ? allContainers.filter(
              (allContainers) =>
                allContainers.number ===
                scanData.text.slice(scanData.text.lastIndexOf('/') + 1)
            )
          : matContainers.filter(
              (allContainers) =>
                allContainers.number ===
                scanData.text.slice(scanData.text.lastIndexOf('/') + 1)
            );
      form.setFieldsValue({
        warehouse_containers: Containers[0].id
      });
      if (Containers?.length) {
        const list = [...materials];
        list[index]['batches'][i]['sub_batches'][sub_i]['warehouse_container'] =
          Containers[0].number;
        list[index]['batches'][i]['sub_batches'][sub_i]['container_id'] =
          Containers[0].id;
        list[index]['batches'][i]['sub_batches'][sub_i]['batch_id'] =
          Containers[0]?.batch_detail?.id;
        list[index]['batches'][i]['sub_batches'][sub_i]['batch_number'] =
          Containers[0]?.batch_detail?.inhouse_batch_number;
        setMaterials(list);
        setOpenCam(false);
      } else {
        message.error('This container does not belong to this batch');
        setOpenCam(false);
      }
    }
  };
  const handleAddbatches = (index, id, lot_number) => {
    setDispenseanother(true);
    let temp_materials = [...materials];
    const disp_hist = materials?.[0]?.dispense_history?.filter(
      (item) => item?.material_id == temp_materials[index].material_id
    );

    const lot = disp_hist?.filter(
      (item) => item?.lot_no == lot_number || item?.lot_no == 'dispense_qty'
    );
    let temp_batches = [...materials];
    console.log(lot);
    temp_batches[index].batches.push({
      dispenses: [
        {
          dispensing_container: `DC-${twoDigitYear}-0001`,
          tare_weight: '',
          net_weight: '',
          gross_weight: 0,
          max_net_weight: 0
        }
      ],
      sub_batches: [
        {
          warehouse_container: '',
          is_left_over: 'No',
          batch_id: lot[temp_materials[index].batches.length]?.batch_details_id,
          batch_number:
            lot[temp_materials[index].batches.length]?.batch_number || '',
          dispense_qty:
            lot[temp_materials[index].batches.length]?.dispensed_qty || ''
        }
      ]
    });
    const payload = { material_id: id };
    type == 'bmr'
      ? dispatch(getContainers(payload))
      : dispatch(getDispenseMaterialDetails(payload)).then(function (response) {
          if (response?.payload?.data?.dispensing_materials?.length) {
            setMatContainers(
              response?.payload?.data?.dispensing_materials?.map((item) => {
                return item?.batch_detail?.containers || [];
              })
            );
          }
        });
    setMaterials(temp_materials);
  };

  const handleAddsubbatches = (index, i) => {
    setDispenseanother(true);
    let temp_materials = [...materials];
    temp_materials[index].batches[i].sub_batches.push({
      warehouse_container: '',
      is_left_over: 'No',
      batch_id: temp_materials[index].batches[i].sub_batches[0]?.batch_id,
      batch_number:
        temp_materials[index].batches[i].sub_batches[0]?.batch_number || ''
    });

    setMaterials(temp_materials);
  };
  const handleDeletesubbatches = (index, i, sub_bat_i) => {
    let temp_materials = [...materials];
    temp_materials[index].batches[i].sub_batches.splice(sub_bat_i, 1);
    setMaterials(temp_materials);
  };
  const handleAddDispensing = (index, i) => {
    let temp_materials = [...materials];
    const last_dispense = temp_materials[index].batches[i]?.dispenses[
      temp_materials[index].batches[i]?.dispenses.length - 1
    ].dispensing_container.slice(
      temp_materials[index].batches[i]?.dispenses[
        temp_materials[index].batches[i]?.dispenses.length - 1
      ].dispensing_container.lastIndexOf('-') + 1
    );
    var net_total = 0;
    temp_materials[index].batches[i]?.dispenses?.map((item) => {
      net_total += item.net_weight;
    });
    setNetTotal(net_total);

    temp_materials[index].batches[i]?.dispenses.push({
      dispensing_container:
        `DC-${twoDigitYear}-` +
        (parseInt(last_dispense) + 1).toString().padStart(4, '0'),
      tare_weight: '',
      net_weight: '',
      gross_weight: 0,
      max_net_weight: net_total
    });

    setMaterials(temp_materials);
  };

  const downloadprintIssue = (index, i, dis_i) => {
    console.log(newMaterialsdata, index, i, dis_i);
    // window.open(url, '_blank');
    let pdf_id =
      newMaterialsdata[index].material_lots[i]?.material
        ?.new_dispensary_containers[dis_i].dispensing_requests_pdf_id;
    let url = newMaterialsdata[index].material_lots[
      i
    ]?.material?.dispensing_request_pdfs.filter((id) => id.id === pdf_id);
    console.log(pdf_id);
    window.open(url[0]?.pdf_doc, '_blank');
  };

  const handleChangeTare = (index, i, dis_i, e) => {
    const list = [...materials];
    list[index]['batches'][i]['dispenses'][dis_i]['tare_weight'] = e;
    list[index]['batches'][i]['dispenses'][dis_i]['gross_weight'] =
      parseFloat(e) +
      parseFloat(
        list[index]['batches'][i]['dispenses'][dis_i]['net_weight'] !== ''
          ? list[index]['batches'][i]['dispenses'][dis_i]['net_weight']
          : 0
      );
    setMaterials(list);
  };

  const handleChangeNet = (index, i, dis_i, e) => {
    const list = [...materials];
    list[index]['batches'][i]['dispenses'][dis_i]['net_weight'] = e;
    list[index]['batches'][i]['dispenses'][dis_i]['gross_weight'] =
      parseFloat(e) +
      parseFloat(
        list[index]['batches'][i]['dispenses'][dis_i]['tare_weight'] !== ''
          ? list[index]['batches'][i]['dispenses'][dis_i]['tare_weight']
          : 0
      );

    var sum = 0;

    list[index]['batches'].map((item) => {
      item.dispenses?.map((item) => {
        sum += parseFloat(item?.net_weight);
      });
    });
    list[index]['total_net_weight'] = sum;
    var net_total = 0;
    list[index].batches[i]?.dispenses?.map((item) => {
      net_total += parseFloat(item.net_weight);
    });
    setNetNextQty(net_total);

    setMaterials(list);
    setTotalNet(sum);
  };

  const handlePrintIssueTag = (index) => {
    // setShowIcon(true);
    setDispenseanother(false);
    console.log(materials[index], 'materials');
    materials[index].ip_address = ipAddress;
    materials[index].eqp_name = balanceName;
    materials[index].bal_name = balanceCode;
    let data = [materials[index]];

    const filteredData = data.map((material) => {
      const filteredBatches = material.batches.map((batch) => {
        let filteredDispenses = batch.dispenses.filter(
          (dispense) => !dispense.dispense_container_id
        );
        // let filteredSubbatches = batch.sub_batches.filter((batch) => !batch.id);
        return {
          ...batch,
          dispenses: filteredDispenses
          // sub_batches: filteredSubbatches
        };
      });
      return filteredBatches[parseInt(filteredBatches.length) - 1]?.dispenses[0]
        ?.dispensing_container === 'DC-25-0001'
        ? {
            ...material,
            batches: [filteredBatches[parseInt(filteredBatches.length) - 1]]
          }
        : {
            ...material,
            batches: [filteredBatches[parseInt(filteredBatches.length) - 1]],
            dc_type: 'secondary_container'
          };
    });
    console.log(filteredData, 'new Data');
    const payload = {
      // dispense_materials: [materials[index]]
      dispense_materials: filteredData
      // lot_no:
      //   productType === 'BPR' && materials[index]?.product_type !== 'API'
      //     ? index + 1 <= 2
      //       ? 1
      //       : 2
      //     : index + 1,
      // part_no:
      //   productType === 'BPR' && materials[index]?.product_type !== 'API'
      //     ? index + 1 == 3
      //       ? 1
      //       : index + 1 == 4
      //       ? 2
      //       : index + 1
      //     : 'NA'
    };
    if (materialsLength > 0) {
      dispatch(updateprintIssue(payload)).then(function (response) {
        if (response?.payload?.success) {
          // setShowIcon(false);
          // setNewMaterials([...newMaterials, materials[index]]);
          setSkeltonActive(true);
          handleGetAllMaterials();
          // history.push('/dispense-material-list');
          window.open(response?.payload?.data, '_blank');
        }
      });
    } else {
      dispatch(createprintIssue(payload)).then(function (response) {
        if (response?.payload?.success) {
          // setShowIcon(false);
          // history.push('/dispense-material-list');
          window.open(response?.payload?.data, '_blank');
        }
      });
    }
  };

  const handleProceedTodeduct = (index) => {
    setIsLoading(true);
    console.log(materials[index], 'deductionmaterials');
    const payload = {
      dispense_request_id: materials[index]?.dispensing_request_id,
      material_id: materials[index]?.material_id,
      material_part_id: materials[index]?.material_part_id,
      material_lot_id: materials[index]?.material_lot_id
    };
    dispatch(stockDeduct(payload)).then(function (response) {
      if (response?.payload?.success) {
        message.success(response?.payload?.message);
        handleGetAllMaterials();
        setIsLoading(false);
        // history.push('/dispense-material-list');
      } else {
        setIsLoading(false);
      }
    });
  };
  // const handlepdfPrintIssueTag = (index, pdf_id) => {
  //   console.log(pdf_id);
  //   setShowIcon(true);
  //   const payload = {
  //     dispense_materials: [
  //       {
  //         dispensing_request_id: params.id,
  //         dispense_request_pdf_id: pdf_id
  //       }
  //     ]
  //   };
  //   dispatch(createprintIssue(payload)).then(function (response) {
  //     if (response?.payload?.success) {
  //       setShowIcon(false);
  //       handleGetAllMaterials();
  //       // history.push('/dispense-material-list');
  //       window.open(response?.payload?.data, '_blank');
  //       // setPrintIssue(false);
  //       // history.push('/dispensing-list');
  //     }
  //   });
  // };

  const handleChangeActual = (index, e, type) => {
    const list = [...materials];
    list[index][type] = e.target.value;
  };
  const UpdateContainers = (index, i, dis_i) => {
    // const payload = {
    //   dispense_materials: [materials[index]]
    // };
    console.log(index, i, dis_i, materials, 'payload2001');
    const filteredData = [materials[index]].map((material) => {
      const filteredBatches = material.batches.map((batch) => {
        let filteredDispenses = batch.dispenses[dis_i];
        // let filteredSubbatches = batch.sub_batches.filter((batch) => !batch.id);
        return {
          ...batch,
          dispenses: [filteredDispenses]
          // sub_batches: filteredSubbatches
        };
      });
      console.log(filteredBatches, 'filteredBatches');
      return {
        ...material,
        batches: [filteredBatches[i]]
      };
    });
    // setDispenseMaterial([materials[index]]);
    setDispenseMaterial(filteredData);
    setIsModal(true);
    // dispatch(updateprintIssue(payload)).then(function (response) {
    //   if (response?.payload?.success) {
    //     message.success(response?.payload?.message);
    //     // window.open(response?.payload?.data, '_blank');
    //     // history.push(`/dispensing-material/${params.id}`);
    //   }
    // });
  };

  console.log(dispenseMaterial, 'dispenseMaterial2001');
  // const handleEdit = (value) => {
  //   setIsEdit({ [value]: true });
  // };

  const handleEdit = (index, i, dis_i) => {
    // setIsEdit({ [value]: true });
    const list = [...materials];
    list[index]['batches'][i]['dispenses'][dis_i]['isEdit'] = true;
    setMaterials(list);
  };

  const confirm = (e) => {
    console.log(e);
    let payload = { dispensing_request_id: params.id };
    dispatch(updateRequestStatus(payload)).then(function (response) {
      if (response?.payload?.success) {
        history.push(`/dispense-material-list`);
      }
    });
  };

  const handleRecalculate = () => {
    setShowModal(true);
  };

  const handleDelete = (value, batches, id) => {
    setIsDelete({ [value]: true });
    setStockBatches(batches);
    setStockMatID(id);
  };

  const handleDispenseBooth = (index, i, dis_i, weight_type) => {
    console.log(index, i, dis_i);
    // const list = [...materials];
    // if (weight_type == 'tare_weight') {
    //   list[index]['batches'][i]['dispenses'][dis_i]['tare_weight'] = 2;
    // } else {
    //   list[index]['batches'][i]['dispenses'][dis_i]['net_weight'] = 2;
    // }
    const payload = { ip: ipAddress };
    if (payload?.ip) {
      dispatch(getBalance(payload)).then((response) => {
        if (response?.payload?.success) {
          setTotalNet(0);
          const list = [...materials];
          if (weight_type == 'tare_weight') {
            list[index]['batches'][i]['dispenses'][dis_i]['tare_weight'] =
              response?.payload?.data;
            list[index]['batches'][i]['dispenses'][dis_i]['gross_weight'] = (
              Math.floor(parseFloat(response?.payload?.data) * 1000) / 1000
            ).toFixed(3);
            // parseFloat(
            //   list[index]['batches'][i]['dispenses'][dis_i]['tare_weight'] !==
            //     ''
            //     ? list[index]['batches'][i]['dispenses'][dis_i]['tare_weight']
            //     : 0
            // );
            setMaterials(list);
          } else {
            list[index]['batches'][i]['dispenses'][dis_i]['net_weight'] =
              response?.payload?.data;

            let result =
              parseFloat(
                (
                  Math.floor(
                    parseFloat(
                      list[index]['batches'][i]['dispenses'][dis_i][
                        'tare_weight'
                      ]
                    ) * 1000
                  ) / 1000
                ).toFixed(3)
              ) +
              parseFloat(
                (
                  Math.floor(parseFloat(response?.payload?.data) * 1000) / 1000
                ).toFixed(3)
              );
            result = parseFloat(result.toFixed(3));
            list[index]['batches'][i]['dispenses'][dis_i]['gross_weight'] =
              result;
            // parseFloat(
            //   list[index]['batches'][i]['dispenses'][dis_i]['tare_weight'] !==
            //     ''
            //     ? list[index]['batches'][i]['dispenses'][dis_i]['tare_weight']
            //     : 0
            // );
            //   (Math.floor(parseFloat(response?.payload?.data) * 1000) / 1000).toFixed(3)
            // +(Math.floor(parseFloat(response?.payload?.data) * 1000) / 1000).toFixed(3);
            var sum = 0;

            list[index]['batches'].map((item) => {
              item.dispenses?.map((item) => {
                sum += parseFloat(item?.net_weight);
              });
            });
            list[index]['total_net_weight'] = sum;
            console.log(list[index].batches[i]?.dispenses, 'disenses');
            var net_total = 0;
            list[index].batches[i]?.dispenses?.map((item) => {
              net_total += parseFloat(item.net_weight);
            });
            console.log(sum, 'Net_total');
            setNetNextQty(net_total);

            setMaterials(list);
            setTotalNet(sum);
          }
        }
      });
      console.log(payload);
    } else {
      message.error('Please select equipment name before you read weight');
    }
  };
  const disabledbtn = materials.every((item) => item.pdf_url);
  return (
    <Layout sider={false}>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Card
          title={
            <span style={{ fontWeight: 'bold' }}>
              Dispense Material -{' '}
              {type == 'bmr'
                ? extragranuler
                  ? `BMR/BPR Based - Extra Granular Ingredient's`
                  : `BMR/BPR Based`
                : 'Material Based'}
            </span>
          }
          className={styles.CustomPanel}
          extra={
            <Space>
              <Popconfirm
                title="Initiate LC Checklist"
                description="Are you sure you want to initiate LC Checklist?"
                onConfirm={confirm}
                // onCancel={cancel}
                okText="Initiate"
                cancelText="Cancel"
              >
                <CustomButton>Initiate LC</CustomButton>
              </Popconfirm>
              <CustomButton type="primary" onClick={() => handleRecalculate()}>
                Recalculate Quantities by Yield
              </CustomButton>
              {/* <span style={{ fontWeight: 'bold' }}>
                {materialDetails?.bmr_number?.toUpperCase()}
              </span> */}
            </Space>
          }
        >
          <>
            {type == 'bmr' ? (
              <Card className={styles.CustomPanel1}>
                <Row gutter={24}>
                  <Col xs={24} xl={6} span={6}>
                    <FormItem
                      label="BMR Number"
                      // name="bmr_number"
                      rules={[
                        {
                          required: false,
                          message: 'Enter BMR Number'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Input
                        // name="bmr_number"
                        // onChange={handleCapacity}
                        placeholder="Enter BMR Number"
                        value={materialDetails?.product_detail?.bmr_bpr_code}
                        disabled
                      />
                    </FormItem>
                  </Col>
                  <Col span={6}>
                    <FormItem
                      label="Product Name"
                      className={styles.customLable}
                    >
                      <Input
                        disabled
                        value={materialDetails?.product_detail?.name || 'NA'}
                      />
                    </FormItem>
                  </Col>
                  <Col span={6}>
                    <FormItem
                      label="Product Batch Number"
                      className={styles.customLable}
                    >
                      <Input
                        disabled
                        value={materialDetails?.product_batch_number || 'NA'}
                      />
                    </FormItem>
                  </Col>

                  <Col span={6}>
                    <FormItem
                      label="Effective Date"
                      className={styles.customLable}
                    >
                      <Input
                        disabled
                        value={
                          materialDetails?.effective_date
                            ? moment(materialDetails?.effective_date).format(
                                'DD MMM YYYY'
                              )
                            : 'NA'
                        }
                      />
                    </FormItem>
                  </Col>
                  <Col span={6}>
                    <FormItem label="Batch Size" className={styles.customLable}>
                      <Input
                        disabled
                        value={
                          materialDetails?.product_detail
                            ?.theoretical_batch_size || 'NA'
                        }
                      />
                    </FormItem>
                  </Col>

                  <Col span={6}>
                    <FormItem label="MFC Number" className={styles.customLable}>
                      <Input
                        disabled
                        value={materialDetails?.ref_mfc || 'NA'}
                      />
                    </FormItem>
                  </Col>
                  <Col span={6}>
                    <FormItem
                      label="Product Code"
                      className={styles.customLable}
                    >
                      <Input
                        disabled
                        value={materialDetails?.product_code || 'NA'}
                      />
                    </FormItem>
                  </Col>
                  <Col span={6}>
                    <FormItem
                      label="Lable Claim"
                      className={styles.customLable}
                    >
                      <Input
                        disabled
                        value={materialDetails?.label_claim || 'NA'}
                      />
                    </FormItem>
                  </Col>
                  <Col span={6}>
                    <FormItem
                      label="Manufacturing Date"
                      className={styles.customLable}
                    >
                      <Input
                        disabled
                        value={
                          moment(materialDetails?.manufacturing_date).format(
                            'DD MMM YYYY'
                          ) || 'NA'
                        }
                      />
                    </FormItem>
                  </Col>
                  <Col span={6}>
                    <FormItem
                      label="Expiry Date"
                      className={styles.customLable}
                    >
                      <Input
                        disabled
                        value={materialDetails?.expiry_date || 'NA'}
                      />
                    </FormItem>
                  </Col>

                  <Col span={6}>
                    <FormItem
                      label="Manufacturing Site"
                      className={styles.customLable}
                    >
                      <Input
                        disabled
                        value={materialDetails?.manufacturing_site || 'NA'}
                      />
                    </FormItem>
                  </Col>
                  <Col span={6}>
                    <FormItem
                      label="Market / Customer Details"
                      className={styles.customLable}
                    >
                      <Input
                        disabled
                        value={materialDetails?.customer_details || 'NA'}
                      />
                    </FormItem>
                  </Col>
                  <Col span={6}>
                    <FormItem
                      label="Parent batch"
                      className={styles.customLable}
                    >
                      <Input
                        disabled
                        value={materialDetails?.parent_batch || 'NA'}
                      />
                    </FormItem>
                  </Col>
                  <Col xs={24} xl={12} span={12}>
                    <FormItem
                      label="Equipment Name"
                      name="equipment_name"
                      className={styles.customLable}
                      rules={[
                        {
                          required: true,
                          message: 'Please select the equipment name'
                        }
                      ]}
                    >
                      <Select
                        placeholder="Select Balance Name"
                        onChange={(e) => handleBalance(e)}
                      >
                        {dispenseBooths?.map((item, index) => {
                          return (
                            <option value={item?.ip_address} key={index}>
                              {item?.balance_name}
                            </option>
                          );
                        })}
                      </Select>
                      {/* <Input
                        disabled
                        value={
                          materialDetails?.product_detail?.equipment_name ||
                          'NA'
                        }
                      /> */}
                    </FormItem>
                  </Col>
                  <Col xs={24} xl={12} span={12}>
                    <FormItem
                      label="Balance Name"
                      className={styles.customLable}
                    >
                      <Input disabled value={balanceCode || 'NA'} />
                      {/* <Select
                        placeholder="Select Balance Name"
                        onChange={(e) => handleBalance(e)}
                      >
                        {dispenseBooths?.map((item, index) => {
                          return (
                            <option value={item?.ip_address} key={index}>
                              {item?.balance_name}
                            </option>
                          );
                        })}
                      </Select> */}
                    </FormItem>
                  </Col>
                </Row>
              </Card>
            ) : (
              ''
            )}
            <br />
            {console.log(materials, 'dispense_containers007')}
            {skeltonActive ? (
              <Skeleton active />
            ) : (
              <Space direction="vertical" className={styles.cardWidth}>
                {materials?.length
                  ? materials?.map((item, index) => {
                      const warehouseContainers =
                        type == 'bmr' ? allContainers : matContainers[index];
                      // const material_codes = allMaterials?.rows?.filter(
                      //   (material) =>
                      //     material.name.substring(0, 12) ==
                      //     materials[index]?.material_name.substring(0, 12)
                      // );
                      const material_codes = item?.material_sequences;
                      console.log(material_codes, 'material_codes');
                      // newMaterialsdata[
                      //   index
                      // ].material_lots[
                      //   i
                      // ]?.material?.new_dispensary_containers.filter(
                      //   (material_id) =>
                      //     material_id.material_part_id ===
                      //     materials[index]
                      //       .material_part_id
                      // );

                      let get_parts = newMaterialsdata[
                        bmrcount
                      ]?.material_lots.filter(
                        (lot_id) =>
                          lot_id.id === materials[index].material_lot_id
                      );
                      let part_id_find = materials[index]?.is_part_exist
                        ? get_parts[0]?.material?.new_dispensary_containers?.filter(
                            (material_id) =>
                              material_id.material_part_id ===
                              materials[index].material_part_id
                          )
                        : true;
                      console.log(materials, get_parts, bmrcount, index);
                      console.log(
                        part_id_find,
                        materials[index]?.is_stock_deduct,
                        'material_codes12345'
                      );
                      // var print_dis =
                      //   parseFloat(materials[index]?.actual_quantity) > 0
                      //     ? parseFloat(
                      //         materials[index]?.actual_quantity
                      //       ).toFixed(3) > 0 &&
                      //       parseFloat(materials[0]?.total_net_weight) > 0 &&
                      //       (parseFloat(
                      //         materials[index]?.actual_quantity || 0
                      //       ).toFixed(3) ===
                      //         parseFloat(
                      //           materials[index]?.total_net_weight
                      //         ).toFixed(3) ||
                      //         Math.abs(
                      //           parseFloat(item?.actual_quantity).toFixed(3) -
                      //             parseFloat(item?.total_net_weight).toFixed(3)
                      //         ) <= 0.1)
                      //       ? false
                      //       : true
                      //     : true;
                      console.log(materials, 'dispense_containers');
                      return 104 >= index ? (
                        <Collapse
                          collapsible="header"
                          defaultActiveKey={['1']}
                          key={index}
                          className={styles.cardStyles_approver2}
                        >
                          <Panel
                            className={styles.cardStyles_approver1}
                            header={
                              <span
                                style={{
                                  color: '#c91761',
                                  fontWeight: 'bold',
                                  fontSize: '14px'
                                }}
                              >
                                {!item?.is_extra &&
                                productType === 'BPR' &&
                                item?.product_type !== 'API'
                                  ? `Material ${bmrcount + 1} - ${item.title}`
                                  : !item?.is_extra &&
                                    productType === 'BPR' &&
                                    item?.product_type === 'API'
                                  ? `Material ${bmrcount + 1} -  ${item.title}`
                                  : !item?.is_extra && productType !== 'BPR'
                                  ? `Material ${bmrcount + 1} -  ${item.title}`
                                  : `Material ${bmrcount + 1}`}
                              </span>
                            }
                            extra={
                              <Space size={6}>
                                {user?.level == 3 ||
                                Superadmindata?.[0]?.level == 3 ? (
                                  <>
                                    <CustomButton
                                      className={styles.border_button}
                                      onClick={() =>
                                        handleDelete(
                                          index,
                                          item?.batches,
                                          materials[index]
                                        )
                                      }
                                    >
                                      <CloseOutlined />
                                    </CustomButton>
                                    {/* <CustomButton
                                      className={styles.border_button}
                                      onClick={() => handleEdit(index)}
                                    >
                                      <EditOutlined />
                                    </CustomButton> */}
                                  </>
                                ) : (
                                  ''
                                )}
                                {/* {user?.role === 'user' &&
                                user?.level == 3 &&
                                // user?.level == 2 &&
                                item?.pdf_url ? (
                                  <Tooltip
                                    placement="top"
                                    title={'Download Print Issue Tag'}
                                  >
                                    <CustomButton
                                      className={styles.border_button}
                                      onClick={() =>
                                        downloadprintIssue(item?.pdf_url)
                                      }
                                    >
                                      <CloudDownloadOutlined />
                                    </CustomButton>
                                  </Tooltip>
                                ) : (
                                  ''
                                )} */}
                              </Space>
                            }
                            key="1"
                          >
                            {console.log(
                              materials[index - 1]?.pdf_url,
                              'SSSSS'
                            )}
                            <Row gutter={24}>
                              <Col xs={24} xl={12} span={12}>
                                <FormItem
                                  label="Material Code"
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Select Material Code'
                                    }
                                  ]}
                                  className={styles.customLable}
                                >
                                  {type !== 'bmr' ? (
                                    <Input
                                      disabled
                                      value={`${materials[index]?.material_code}`}
                                    />
                                  ) : !item?.dispense_containers ? (
                                    <Select
                                      showSearch
                                      placeholder="Select a material code"
                                      optionFilterProp="children"
                                      onChange={(e) =>
                                        onChange(
                                          index,
                                          e,
                                          item?.is_extra,
                                          item?.lot_number,
                                          item?.is_part_exist,
                                          item?.lot_no,
                                          item?.part_index
                                        )
                                      }
                                      onSearch={onSearch}
                                      value={selectSecondValue[index]}
                                      disabled={
                                        (materials[index - 1]?.pdf_url &&
                                          materials[index - 1]
                                            ?.is_stock_deduct) ||
                                        index == 0
                                          ? false
                                          : true
                                      }
                                    >
                                      {material_codes?.map((item, index) => {
                                        return (
                                          <option value={item.id} key={index}>
                                            {item.code}
                                          </option>
                                        );
                                      })}
                                    </Select>
                                  ) : (
                                    <Input
                                      disabled
                                      value={`${materials[index]?.material_code}`}
                                    />
                                  )}
                                </FormItem>
                              </Col>
                              <Col xs={24} xl={12} span={12}>
                                <FormItem
                                  label="Material Name"
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Select Material Code'
                                    }
                                  ]}
                                  className={styles.customLable}
                                >
                                  <Input
                                    disabled
                                    value={`${materials[index]?.material_name}`}
                                  />
                                </FormItem>
                              </Col>
                              <Col xs={24} xl={8} span={8}>
                                <FormItem
                                  label="Standard Quantity(KG)"
                                  className={styles.customLable}
                                >
                                  <Input disabled value={`${item?.lot}`} />
                                </FormItem>
                              </Col>
                              <Col xs={24} xl={8} span={8}>
                                <FormItem
                                  label="Total Actual Quantity (KG) to Dispense"
                                  className={styles.customLable}
                                >
                                  <Input
                                    placeholder="Enter Actual Quantity"
                                    value={
                                      item?.actual_quantity
                                        ? parseFloat(
                                            item?.actual_quantity
                                          ).toFixed(3)
                                        : 0
                                    }
                                    disabled
                                    onChange={(e) =>
                                      handleChangeActual(
                                        index,
                                        e,
                                        'actual_quantity'
                                      )
                                    }
                                  />
                                </FormItem>
                              </Col>
                              <Col xs={24} xl={8} span={8}>
                                <FormItem
                                  label="Total Net Weight"
                                  className={styles.customLable}
                                >
                                  <Input
                                    placeholder="Enter Total Net Weight"
                                    value={
                                      item?.total_net_weight !== '' &&
                                      item?.total_net_weight > 0
                                        ? parseFloat(
                                            item?.total_net_weight
                                          ).toFixed(3)
                                        : 0
                                    }
                                    disabled
                                    onChange={(e) =>
                                      handleChangeActual(
                                        index,
                                        e,
                                        'total_net_weight'
                                      )
                                    }
                                  />
                                </FormItem>
                              </Col>
                            </Row>

                            {item?.batches?.length
                              ? item?.batches?.map((batch_item, i) => {
                                  let batches = dispenseanother
                                    ? batch_item?.sub_batches
                                    : [batch_item?.sub_batches[i]];

                                  return (
                                    <>
                                      <Card
                                        className={`${styles.cardStyles_approver}`}
                                        key={i}
                                      >
                                        {console.log(batches, i, 'jjjj')}
                                        {batches?.map(
                                          (sub_batch_item, sub_bat_i) => {
                                            let containers =
                                              warehouseContainers?.filter(
                                                (item) =>
                                                  item.batch_details_id ==
                                                  sub_batch_item?.batch_id
                                              );

                                            return (
                                              <div key={sub_bat_i}>
                                                <Row
                                                  gutter={24}
                                                  key={sub_bat_i}
                                                >
                                                  {(user?.level == 3 ||
                                                    Superadmindata?.[0]
                                                      ?.level == 3) &&
                                                  item?.is_drafted ? (
                                                    ''
                                                  ) : materials[index]?.batches[
                                                      i
                                                    ]?.sub_batches[sub_bat_i]
                                                      ?.container_id &&
                                                    materials[index]
                                                      ?.is_drafted &&
                                                    materials[index]?.batches[i]
                                                      ?.sub_batches[sub_bat_i]
                                                      ?.warehouse_container ==
                                                      'NA' ? (
                                                    ''
                                                  ) : (
                                                    <Col
                                                      xs={4}
                                                      xl={2}
                                                      span={2}
                                                      md={2}
                                                      sm={2}
                                                    >
                                                      <CustomButton
                                                        onClick={() =>
                                                          setData({
                                                            index,
                                                            i,
                                                            sub_bat_i
                                                          })
                                                        }
                                                        className={
                                                          styles.qrcode
                                                        }
                                                      >
                                                        <QrcodeOutlined
                                                          onClick={() =>
                                                            setOpenCam(true)
                                                          }
                                                        />
                                                      </CustomButton>
                                                    </Col>
                                                  )}

                                                  {(user?.level == 3 ||
                                                    Superadmindata?.[0]
                                                      ?.level == 3) &&
                                                  item?.is_drafted ? (
                                                    ''
                                                  ) : materials[index]?.batches[
                                                      i
                                                    ]?.sub_batches[sub_bat_i]
                                                      ?.container_id &&
                                                    materials[index]
                                                      ?.is_drafted &&
                                                    materials[index]?.batches[i]
                                                      ?.sub_batches[sub_bat_i]
                                                      ?.warehouse_container ==
                                                      'NA' ? (
                                                    ''
                                                  ) : (
                                                    <>
                                                      <Col
                                                        xs={20}
                                                        xl={5}
                                                        span={6}
                                                        md={10}
                                                        sm={10}
                                                      >
                                                        <FormItem
                                                          label="Warehouse Containers"
                                                          rules={[
                                                            {
                                                              required: false,
                                                              message:
                                                                'Enter warehouse containers'
                                                            }
                                                          ]}
                                                          className={
                                                            styles.customLable
                                                          }
                                                        >
                                                          {materials[index]
                                                            ?.batches[i]
                                                            ?.sub_batches[
                                                            sub_bat_i
                                                          ]
                                                            ?.warehouse_container !==
                                                          '' ? (
                                                            <Input
                                                              value={
                                                                materials[index]
                                                                  ?.batches[i]
                                                                  ?.sub_batches[
                                                                  dispenseanother
                                                                    ? sub_bat_i
                                                                    : i
                                                                ]
                                                                  ?.warehouse_container
                                                              }
                                                              disabled
                                                            />
                                                          ) : (
                                                            <Select
                                                              showSearch
                                                              placeholder="Scan or select container"
                                                              optionFilterProp="children"
                                                              onChange={(e) =>
                                                                onChangeWarehouse(
                                                                  index,
                                                                  i,
                                                                  sub_bat_i,
                                                                  e
                                                                )
                                                              }
                                                              onSearch={(e) =>
                                                                onSearch(
                                                                  e,
                                                                  materials[
                                                                    index
                                                                  ]?.material_id
                                                                )
                                                              }
                                                            >
                                                              {containers?.map(
                                                                (
                                                                  item,
                                                                  index
                                                                ) => {
                                                                  return (
                                                                    <option
                                                                      value={
                                                                        item?.id
                                                                      }
                                                                      key={
                                                                        index
                                                                      }
                                                                    >
                                                                      {
                                                                        item?.number
                                                                      }
                                                                    </option>
                                                                  );
                                                                }
                                                              )}
                                                            </Select>
                                                          )}
                                                        </FormItem>
                                                      </Col>
                                                    </>
                                                  )}

                                                  <Col
                                                    xs={24}
                                                    xl={5}
                                                    span={5}
                                                    md={10}
                                                    sm={10}
                                                  >
                                                    <FormItem
                                                      label="Batch Number"
                                                      rules={[
                                                        {
                                                          required: true,
                                                          message:
                                                            'Select batch number'
                                                        }
                                                      ]}
                                                      className={
                                                        styles.customLable
                                                      }
                                                    >
                                                      <Input
                                                        value={
                                                          `${
                                                            materials[index]
                                                              ?.batches[i]
                                                              ?.sub_batches[
                                                              dispenseanother
                                                                ? sub_bat_i
                                                                : i
                                                            ]?.batch_number ===
                                                            undefined
                                                              ? ''
                                                              : materials[index]
                                                                  ?.batches[i]
                                                                  ?.sub_batches[
                                                                  dispenseanother
                                                                    ? sub_bat_i
                                                                    : i
                                                                ]?.batch_number
                                                          }` || ''
                                                        }
                                                        disabled
                                                      />
                                                    </FormItem>
                                                  </Col>
                                                  {sub_bat_i == 0 ? (
                                                    <Col
                                                      xs={24}
                                                      xl={5}
                                                      span={5}
                                                      md={10}
                                                      sm={10}
                                                    >
                                                      <FormItem
                                                        label="Qty to Dispense"
                                                        rules={[
                                                          {
                                                            required: true,
                                                            message:
                                                              'Select batch number'
                                                          }
                                                        ]}
                                                        className={
                                                          styles.customLable
                                                        }
                                                      >
                                                        <Input
                                                          value={
                                                            `${
                                                              materials[index]
                                                                ?.batches[i]
                                                                ?.sub_batches[
                                                                dispenseanother
                                                                  ? sub_bat_i
                                                                  : i
                                                              ]
                                                                ?.dispense_qty ===
                                                              undefined
                                                                ? ''
                                                                : materials[
                                                                    index
                                                                  ]?.batches[i]
                                                                    ?.sub_batches[
                                                                    dispenseanother
                                                                      ? sub_bat_i
                                                                      : i
                                                                  ]
                                                                    ?.dispense_qty
                                                            }` || ''
                                                          }
                                                          disabled
                                                        />
                                                      </FormItem>
                                                    </Col>
                                                  ) : (
                                                    ''
                                                  )}
                                                  {(user?.level == 3 ||
                                                    Superadmindata?.[0]
                                                      ?.level == 3) &&
                                                  item?.is_drafted ? (
                                                    ''
                                                  ) : batch_item?.sub_batches
                                                      .length -
                                                      1 ==
                                                    sub_bat_i ? (
                                                    <Col
                                                      xs={24}
                                                      xl={
                                                        sub_bat_i == 0 ? 4 : 6
                                                      }
                                                      span={6}
                                                      md={10}
                                                      sm={10}
                                                      className={styles.qr}
                                                    >
                                                      <FormItem
                                                        rules={[
                                                          {
                                                            required: false,
                                                            message:
                                                              'Enter warehouse containers'
                                                          }
                                                        ]}
                                                        className={
                                                          styles.customLable
                                                        }
                                                        onChange={(e) =>
                                                          onChangeleftover(
                                                            index,
                                                            i,
                                                            sub_bat_i,
                                                            e
                                                          )
                                                        }
                                                        name={`left_over${index}${i}`}
                                                      >
                                                        <Radio.Group
                                                          value={
                                                            sub_batch_item?.is_left_over ==
                                                            'Yes'
                                                              ? `${i}${sub_bat_i}`
                                                              : ''
                                                          }
                                                        >
                                                          <Radio
                                                            value={`${i}${sub_bat_i}`}
                                                          >
                                                            Left Over Container
                                                          </Radio>
                                                        </Radio.Group>
                                                      </FormItem>
                                                    </Col>
                                                  ) : (
                                                    ''
                                                  )}
                                                  {sub_bat_i > 0 ? (
                                                    (user?.level == 3 ||
                                                      Superadmindata?.[0]
                                                        ?.level == 3) &&
                                                    item?.is_drafted ? (
                                                      ''
                                                    ) : (
                                                      <Col
                                                        xs={6}
                                                        xl={2}
                                                        span={2}
                                                        md={2}
                                                        sm={2}
                                                      >
                                                        <CustomButton
                                                          onClick={() =>
                                                            handleDeletesubbatches(
                                                              index,
                                                              i,
                                                              sub_bat_i
                                                            )
                                                          }
                                                          className={styles.qr}
                                                        >
                                                          <CloseOutlined />
                                                        </CustomButton>
                                                      </Col>
                                                    )
                                                  ) : null}
                                                  {batch_item?.sub_batches
                                                    .length -
                                                    1 ==
                                                  sub_bat_i ? (
                                                    (user?.level == 3 ||
                                                      Superadmindata?.[0]
                                                        ?.level == 3) &&
                                                    item?.is_drafted ? (
                                                      ''
                                                    ) : (
                                                      <Tooltip
                                                        placement="top"
                                                        title="Add Next Container"
                                                      >
                                                        <Col
                                                          xs={6}
                                                          xl={2}
                                                          span={2}
                                                          md={2}
                                                          sm={2}
                                                        >
                                                          <CustomButton
                                                            onClick={() =>
                                                              handleAddsubbatches(
                                                                index,
                                                                i
                                                              )
                                                            }
                                                            className={
                                                              styles.qr
                                                            }
                                                          >
                                                            <PlusOutlined />
                                                          </CustomButton>
                                                        </Col>
                                                      </Tooltip>
                                                    )
                                                  ) : null}
                                                </Row>
                                              </div>
                                            );
                                          }
                                        )}
                                        {batch_item?.dispenses?.length
                                          ? batch_item?.dispenses?.map(
                                              (dis, dis_i) => {
                                                console.log(
                                                  materials[index],
                                                  'dis777'
                                                );
                                                // let part_id_find = materials[
                                                //   index
                                                // ]?.is_part_exist
                                                //   ? newMaterialsdata[
                                                //       index
                                                //     ].material_lots[
                                                //       i
                                                //     ]?.material?.new_dispensary_containers?.filter(
                                                //       (material_id) =>
                                                //         material_id.material_part_id ===
                                                //         materials[index]
                                                //           .material_part_id
                                                //     )
                                                //   : true;
                                                console.log(
                                                  part_id_find,
                                                  'part_id_find'
                                                );
                                                return (
                                                  <Row gutter={24} key={dis_i}>
                                                    <Col
                                                      xs={20}
                                                      xl={5}
                                                      span={6}
                                                      md={10}
                                                      sm={10}
                                                    >
                                                      <FormItem
                                                        label="Dispensing Containers"
                                                        rules={[
                                                          {
                                                            required: false,
                                                            message:
                                                              'Enter warehouse containers'
                                                          }
                                                        ]}
                                                        className={
                                                          styles.customLable
                                                        }
                                                      >
                                                        <Input
                                                          value={
                                                            dis?.dispensing_container
                                                          }
                                                          disabled
                                                        />
                                                      </FormItem>
                                                    </Col>
                                                    <Col
                                                      xs={14}
                                                      xl={3}
                                                      span={6}
                                                      md={10}
                                                      sm={10}
                                                    >
                                                      <FormItem
                                                        label="Tare Weight"
                                                        rules={[
                                                          {
                                                            required: false,
                                                            message:
                                                              'Enter warehouse containers'
                                                          }
                                                        ]}
                                                        className={
                                                          styles.customLable
                                                        }
                                                      >
                                                        <InputNumber
                                                          onChange={(e) =>
                                                            handleChangeTare(
                                                              index,
                                                              i,
                                                              dis_i,
                                                              e
                                                            )
                                                          }
                                                          placeholder="Tare Weight"
                                                          min={0}
                                                          max={1000000}
                                                          style={{
                                                            width: '100%',
                                                            color: 'black'
                                                          }}
                                                          value={parseFloat(
                                                            dis?.tare_weight ||
                                                              0
                                                          ).toFixed(3)}
                                                          disabled={
                                                            materialDetails?.bmr_bpr_type ===
                                                              'Manufacturing' ||
                                                            (dis?.dispense_pdf_id &&
                                                              !dis?.isEdit)
                                                              ? true
                                                              : false
                                                          }
                                                          // disabled={
                                                          //   (user?.level == 3 ||
                                                          //     Superadmindata?.[0]
                                                          //       ?.level == 3) &&
                                                          //   item?.is_drafted
                                                          //     ? isEdit[index]
                                                          //       ? false
                                                          //       : true
                                                          //     : item?.is_drafted &&
                                                          //       dis?.dispense_container_id
                                                          //     ? true
                                                          //     : false
                                                          // }
                                                        />
                                                        <CustomButton
                                                          // href=""
                                                          // type="primary"
                                                          // className={
                                                          //   styles.read1
                                                          // }
                                                          style={{
                                                            color: '#c91761',
                                                            marginTop: '2px'
                                                          }}
                                                          size={'small'}
                                                          onClick={() =>
                                                            handleDispenseBooth(
                                                              index,
                                                              i,
                                                              dis_i,
                                                              'tare_weight'
                                                            )
                                                          }
                                                          // disabled={
                                                          //   dis?.tare_weight
                                                          //     ? true
                                                          //     : false
                                                          // }
                                                          // disabled
                                                          // ghost
                                                          // primary
                                                        >
                                                          Read
                                                        </CustomButton>
                                                      </FormItem>
                                                    </Col>
                                                    {isEdit[index]}
                                                    <Col
                                                      xs={8}
                                                      xl={2}
                                                      span={6}
                                                      md={10}
                                                      sm={10}
                                                    >
                                                      <FormItem
                                                        label="UOM"
                                                        rules={[
                                                          {
                                                            required: false,
                                                            message:
                                                              'Enter Net Weight'
                                                          }
                                                        ]}
                                                        className={
                                                          styles.customLable
                                                        }
                                                      >
                                                        <Input
                                                          placeholder="UOM"
                                                          value={
                                                            materials[index]
                                                              ?.is_conversion
                                                              ? 'KG'
                                                              : materials[index]
                                                                  ?.uom
                                                          }
                                                          disabled
                                                        />
                                                      </FormItem>
                                                    </Col>
                                                    <Col
                                                      xs={14}
                                                      xl={3}
                                                      span={6}
                                                      md={10}
                                                      sm={10}
                                                    >
                                                      <FormItem
                                                        label="Net Weight"
                                                        rules={[
                                                          {
                                                            required: false,
                                                            message:
                                                              'Enter Net Weight'
                                                          }
                                                        ]}
                                                        className={
                                                          styles.customLable
                                                        }
                                                      >
                                                        <InputNumber
                                                          min={0}
                                                          // max={Number(
                                                          //   parseFloat(
                                                          //     materials[index]
                                                          //       ?.batches[i]
                                                          //       ?.sub_batches[0]
                                                          //       ?.dispense_qty
                                                          //   ).toFixed(3) -
                                                          //     parseFloat(
                                                          //       dis?.max_net_weight
                                                          //     ).toFixed(3)
                                                          // ).toFixed(3)}
                                                          placeholder="Net Weight"
                                                          onChange={(e) =>
                                                            handleChangeNet(
                                                              index,
                                                              i,
                                                              dis_i,
                                                              e
                                                            )
                                                          }
                                                          style={{
                                                            width: '100%',
                                                            color: 'black'
                                                          }}
                                                          value={parseFloat(
                                                            dis?.net_weight || 0
                                                          ).toFixed(3)}
                                                          // disabled={
                                                          //   (user?.level == 3 ||
                                                          //     Superadmindata?.[0]
                                                          //       ?.level == 3) &&
                                                          //   item?.is_drafted
                                                          //     ? isEdit[index]
                                                          //       ? false
                                                          //       : true
                                                          //     : item?.is_drafted &&
                                                          //       dis?.dispense_container_id
                                                          //     ? true
                                                          //     : false
                                                          // }
                                                          disabled={
                                                            materialDetails?.bmr_bpr_type ===
                                                              'Manufacturing' ||
                                                            (dis?.dispense_pdf_id &&
                                                              !dis?.isEdit)
                                                              ? true
                                                              : false
                                                          }
                                                        />
                                                        <CustomButton
                                                          // href=""
                                                          // type="primary"
                                                          // className={
                                                          //   styles.read1
                                                          // }
                                                          style={{
                                                            color: '#c91761',
                                                            marginTop: '2px'
                                                          }}
                                                          size="small"
                                                          onClick={() =>
                                                            handleDispenseBooth(
                                                              index,
                                                              i,
                                                              dis_i,
                                                              'net_weight'
                                                            )
                                                          }
                                                          // disabled={
                                                          //   dis.tare_weight !==
                                                          //     '' &&
                                                          //   dis.net_weight == ''
                                                          //     ? false
                                                          //     : true
                                                          // }
                                                          // ghost
                                                          // primary
                                                        >
                                                          Read
                                                        </CustomButton>
                                                      </FormItem>
                                                    </Col>
                                                    <Col
                                                      xs={8}
                                                      xl={2}
                                                      span={6}
                                                      md={10}
                                                      sm={10}
                                                    >
                                                      <FormItem
                                                        label="UOM"
                                                        rules={[
                                                          {
                                                            required: false,
                                                            message:
                                                              'Enter Net Weight'
                                                          }
                                                        ]}
                                                        className={
                                                          styles.customLable
                                                        }
                                                      >
                                                        <Input
                                                          placeholder="UOM"
                                                          value={
                                                            materials[index]
                                                              ?.is_conversion
                                                              ? 'KG'
                                                              : materials[index]
                                                                  ?.uom
                                                          }
                                                          disabled
                                                        />
                                                      </FormItem>
                                                    </Col>
                                                    <Col
                                                      xs={14}
                                                      xl={3}
                                                      span={6}
                                                      md={10}
                                                      sm={10}
                                                    >
                                                      <FormItem
                                                        label="Gross Weight"
                                                        rules={[
                                                          {
                                                            required: false,
                                                            message:
                                                              'Enter Gross containers'
                                                          }
                                                        ]}
                                                        className={
                                                          styles.customLable
                                                        }
                                                      >
                                                        <Input
                                                          placeholder="Gross Weight"
                                                          // value={
                                                          //   parseFloat(
                                                          //     dis?.gross_weight
                                                          //   ).toFixed(3) || ''
                                                          // }
                                                          value={(
                                                            (dis?.tare_weight
                                                              ? parseFloat(
                                                                  dis?.tare_weight
                                                                )
                                                              : 0.0) +
                                                            (dis?.net_weight
                                                              ? parseFloat(
                                                                  dis?.net_weight
                                                                )
                                                              : 0.0)
                                                          ).toFixed(3)}
                                                          disabled
                                                        />
                                                      </FormItem>
                                                    </Col>
                                                    <Col
                                                      xs={8}
                                                      xl={2}
                                                      span={6}
                                                      md={10}
                                                      sm={10}
                                                    >
                                                      <FormItem
                                                        label="UOM"
                                                        rules={[
                                                          {
                                                            required: false,
                                                            message:
                                                              'Enter Gross Weight'
                                                          }
                                                        ]}
                                                        className={
                                                          styles.customLable
                                                        }
                                                      >
                                                        <Input
                                                          placeholder="UOM"
                                                          value={
                                                            materials[index]
                                                              ?.is_conversion
                                                              ? 'KG'
                                                              : materials[index]
                                                                  ?.uom
                                                          }
                                                          disabled
                                                        />
                                                      </FormItem>
                                                    </Col>
                                                    {console.log(
                                                      dis?.dispense_pdf_id,
                                                      'dispense_id'
                                                    )}

                                                    {batch_item?.dispenses
                                                      ?.length -
                                                      1 ==
                                                    dis_i ? (
                                                      (user?.level == 3 ||
                                                        Superadmindata?.[0]
                                                          ?.level == 3) &&
                                                      item?.is_drafted ? (
                                                        ''
                                                      ) : (
                                                        <Col
                                                          xs={4}
                                                          xl={4}
                                                          span={2}
                                                          md={2}
                                                          sm={2}
                                                        >
                                                          <Space>
                                                            <Tooltip
                                                              placement="top"
                                                              title="Submit & Print Issue Tag"
                                                            >
                                                              <CustomButton
                                                                // onClick={() =>
                                                                //   handleAddDispensing(
                                                                //     index,
                                                                //     i
                                                                //   )
                                                                // }
                                                                onClick={() =>
                                                                  handlePrintIssueTag(
                                                                    index
                                                                  )
                                                                }
                                                                className={
                                                                  styles.qrcode
                                                                }
                                                                disabled={
                                                                  part_id_find?.length &&
                                                                  materials[
                                                                    index
                                                                  ]
                                                                    ?.is_stock_deduct
                                                                }
                                                                // disabled={
                                                                //   parseFloat(
                                                                //     item?.actual_quantity
                                                                //   ) > 0
                                                                //     ? parseFloat(
                                                                //         item?.actual_quantity
                                                                //       ).toFixed(
                                                                //         3
                                                                //       ) > 0 &&
                                                                //       parseFloat(
                                                                //         item?.total_net_weight
                                                                //       ) > 0 &&
                                                                //       parseFloat(
                                                                //         item?.actual_quantity
                                                                //       ) >=
                                                                //         parseFloat(
                                                                //           item?.total_net_weight
                                                                //         )
                                                                //       ? false
                                                                //       : true
                                                                //     : true
                                                                // }
                                                              >
                                                                <CloudDownloadOutlined />
                                                              </CustomButton>
                                                            </Tooltip>
                                                            {dis?.dispense_pdf_id && (
                                                              <Tooltip
                                                                placement="top"
                                                                title="Add Next Container"
                                                              >
                                                                <CustomButton
                                                                  onClick={() =>
                                                                    handleAddDispensing(
                                                                      index,
                                                                      i
                                                                    )
                                                                  }
                                                                  className={
                                                                    styles.qrcode
                                                                  }
                                                                  disabled={
                                                                    netNextQty >=
                                                                      materials[
                                                                        index
                                                                      ]
                                                                        ?.batches[
                                                                        i
                                                                      ]
                                                                        ?.sub_batches[0]
                                                                        ?.dispense_qty ||
                                                                    (part_id_find?.length &&
                                                                      materials[
                                                                        index
                                                                      ]
                                                                        ?.is_stock_deduct)
                                                                      ? true
                                                                      : false
                                                                  }
                                                                >
                                                                  <PlusOutlined />
                                                                </CustomButton>
                                                              </Tooltip>
                                                            )}
                                                          </Space>
                                                        </Col>
                                                      )
                                                    ) : null}
                                                    {user?.level == 3 && (
                                                      <Col span="4">
                                                        <Space>
                                                          {!dis?.isEdit ? (
                                                            <CustomButton
                                                              className={
                                                                styles.border_button1
                                                              }
                                                              onClick={() =>
                                                                handleEdit(
                                                                  index,
                                                                  i,
                                                                  dis_i
                                                                )
                                                              }
                                                            >
                                                              <EditOutlined />
                                                            </CustomButton>
                                                          ) : (
                                                            <CustomButton
                                                              className={
                                                                styles.border_button1
                                                              }
                                                              onClick={() =>
                                                                UpdateContainers(
                                                                  index,
                                                                  i,
                                                                  dis_i
                                                                )
                                                              }
                                                            >
                                                              <CheckCircleOutlined />
                                                            </CustomButton>
                                                          )}
                                                          {/* <CustomButton
                                                            className={
                                                              styles.border_button1
                                                            }
                                                            onClick={() =>
                                                              UpdateContainers(
                                                                index,
                                                                i,
                                                                dis_i
                                                              )
                                                            }
                                                          >
                                                            <CheckCircleOutlined />
                                                          </CustomButton> */}
                                                          <CustomButton
                                                            className={
                                                              styles.border_button1
                                                            }
                                                            onClick={() =>
                                                              downloadprintIssue(
                                                                index,
                                                                i,
                                                                dis_i
                                                              )
                                                            }
                                                          >
                                                            <CloudDownloadOutlined />
                                                          </CustomButton>
                                                        </Space>
                                                      </Col>
                                                    )}
                                                  </Row>
                                                );
                                              }
                                            )
                                          : ''}
                                      </Card>
                                      <br />
                                    </>
                                  );
                                })
                              : ''}
                            <br />
                            {openCam && index == data?.index ? (
                              <QrReader
                                constraints={{
                                  facingMode: 'environment'
                                }}
                                delay={1000}
                                onError={handleError}
                                onResult={(result) =>
                                  handleQrScan(
                                    data?.index,
                                    data?.i,
                                    data?.sub_bat_i,
                                    result
                                  )
                                }
                                style={{
                                  width: '3px',
                                  height: '3px'
                                }}
                              />
                            ) : (
                              ''
                            )}
                            {console.log(
                              newMaterialsdata[index]?.material_lots,
                              'kkkk'
                            )}

                            <FormItem className="text-center">
                              {
                                item?.is_drafted &&
                                (user?.level == 3 ||
                                  Superadmindata?.[0]?.level ==
                                    3) ? null : user?.role == 'user' ? (
                                  <Space direction="vertical">
                                    <CustomButton
                                      className={styles.border_button}
                                      type="primary"
                                      onClick={() =>
                                        handleAddbatches(
                                          index,
                                          item?.material_id,
                                          item?.lot_number
                                        )
                                      }
                                      loading={loading}
                                      style={{
                                        width: '100%',
                                        borderColor:
                                          part_id_find?.length &&
                                          materials[index]?.is_stock_deduct
                                            ? 'rgb(203 205 203)'
                                            : '#007bff',
                                        color:
                                          part_id_find?.length &&
                                          materials[index]?.is_stock_deduct
                                            ? 'rgb(184 195 187)'
                                            : '#007bff'
                                      }}
                                      ghost
                                      // disabled={item?.pdf_id ? true : false}
                                      disabled={
                                        part_id_find?.length &&
                                        materials[index]?.is_stock_deduct
                                      }
                                      // ghost
                                    >
                                      Dispense from another Batch
                                    </CustomButton>
                                    {showIcon ? (
                                      <Image
                                        src={truck}
                                        preview={false}
                                        // style={{
                                        //   width: '100px !important',
                                        //   height: '100px'
                                        // }}
                                        style={{
                                          width: '100%',
                                          borderColor:
                                            part_id_find?.length &&
                                            materials[index]?.is_stock_deduct
                                              ? 'rgb(203 205 203)'
                                              : '#007bff',
                                          color:
                                            part_id_find?.length &&
                                            materials[index]?.is_stock_deduct
                                              ? 'rgb(184 195 187)'
                                              : '#007bff'
                                        }}
                                        ghost
                                        // disabled={item?.pdf_id ? true : false}
                                        disabled={
                                          part_id_find?.length &&
                                          materials[index]?.is_stock_deduct
                                        }
                                      />
                                    ) : user?.role == 'user' ? (
                                      isLoading ? (
                                        <CustomButton
                                          className={styles.border_button}
                                          loading={loading}
                                          ghost
                                        >
                                          <Loader />
                                        </CustomButton>
                                      ) : (
                                        <CustomButton
                                          className={styles.border_button}
                                          onClick={() =>
                                            // handlePrintIssueTag(index)
                                            handleProceedTodeduct(index)
                                          }
                                          loading={loading}
                                          style={{
                                            width: '100%',
                                            borderColor:
                                              part_id_find?.length &&
                                              materials[index]?.is_stock_deduct
                                                ? 'rgb(203 205 203)'
                                                : '#28a745',
                                            color:
                                              part_id_find?.length &&
                                              materials[index]?.is_stock_deduct
                                                ? 'rgb(184 195 187)'
                                                : '#28a745'
                                          }}
                                          ghost
                                          disabled={
                                            part_id_find?.length &&
                                            materials[index]?.is_stock_deduct
                                          }
                                          // disabled={
                                          //   parseFloat(item?.actual_quantity) > 0
                                          //     ? parseFloat(
                                          //         item?.actual_quantity
                                          //       ).toFixed(3) > 0 &&
                                          //       parseFloat(
                                          //         item?.total_net_weight
                                          //       ) > 0 &&
                                          //       (parseFloat(
                                          //         item?.actual_quantity
                                          //       ).toFixed(3) ===
                                          //         parseFloat(
                                          //           item?.total_net_weight
                                          //         ).toFixed(3) ||
                                          //         Math.abs(
                                          //           parseFloat(
                                          //             item?.actual_quantity
                                          //           ).toFixed(3) -
                                          //             parseFloat(
                                          //               item?.total_net_weight
                                          //             ).toFixed(3)
                                          //         ) <= 0.1)
                                          //       ? false
                                          //       : true
                                          //     : true
                                          // }
                                        >
                                          Proceed to Stock Deduction
                                          {/* Submit & Print Issue Tag */}
                                          {/* Submit */}
                                        </CustomButton>
                                      )
                                    ) : (
                                      ''
                                    )}
                                  </Space>
                                ) : (
                                  ''
                                )
                                // user?.role == 'prod_user' ? (
                                //   showIcon ? (
                                //     <Image
                                //       src={truck}
                                //       preview={false}
                                //       style={{
                                //         width: '100px !important',
                                //         height: '100px'
                                //       }}
                                //     />
                                //   ) : (
                                //     <Space direction="vertical">
                                //       <CustomButton
                                //         className={styles.border_button}
                                //         onClick={() =>
                                //           handlepdfPrintIssueTag(
                                //             index,
                                //             item?.pdf_id
                                //           )
                                //         }
                                //         loading={loading}
                                //         style={{
                                //           width: '100%',
                                //           borderColor: item?.prod_user_id
                                //             ? 'rgb(173 183 175)'
                                //             : '#28a745',
                                //           color: item?.prod_user_id
                                //             ? 'rgb(173 183 175)'
                                //             : '#28a745'
                                //         }}
                                //         disabled={
                                //           item?.prod_user_id ? true : false
                                //         }
                                //         ghost
                                //       >
                                //         Print Issue Tag
                                //       </CustomButton>
                                //     </Space>
                                //   )
                                // ) : (
                                //   ''
                                // )
                              }
                            </FormItem>
                          </Panel>
                        </Collapse>
                      ) : (
                        ''
                      );
                    })
                  : ''}
              </Space>
            )}
          </>
        </Card>
        <br />

        <br />
        {/* {dispenseMat?.length + '---' + bmrcount + 1} */}
        <FormItem className="text-center">
          <Space direction="vertical" size={15}>
            {user?.level == 3 || Superadmindata?.[0]?.level == 3 ? (
              <CustomButton
                onClick={() => handleaddBMRMaterial()}
                loading={loading}
                disabled={!disabledbtn}
              >
                Add material
              </CustomButton>
            ) : type == 'bmr' ? (
              <Space>
                {dispenseMat.filter((e) => e.length)?.length !==
                bmrcount + 1 ? (
                  <CustomButton
                    onClick={() => handleaddBMRMaterial()}
                    loading={loading}
                    disabled={!disabledbtn}
                  >
                    Add material
                  </CustomButton>
                ) : (
                  ''
                )}
              </Space>
            ) : materials?.length > counter ? (
              <CustomButton
                onClick={() => handleaddMaterial()}
                loading={loading}
                disabled={!disabledbtn}
              >
                Add material
              </CustomButton>
            ) : (
              ''
            )}
            {/* {dispenseMat?.length == bmrcount + 1 ? ( */}
            <CustomButton
              loading={loading}
              type="primary"
              htmlType="submit"
              // onClick={() => handleaddBMRMaterial()}
            >
              Print Dispensing Sheet
            </CustomButton>
            {/* ) : (
              ''
            )} */}
          </Space>
        </FormItem>
        {showModal ? (
          <CustomModal
            title="Recalculate Quantity"
            visible={showModal}
            // width={'50%'}
            footer={null}
            className={styles.customModal}
            closable={false}
          >
            <Row gutter={24}>
              <Col xs={24} xl={4} span={8}>
                <FormItem
                  label="Stage"
                  name="Stage"
                  rules={[
                    {
                      required: true,
                      message: 'Select Stage Group'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Select
                    showSearch
                    placeholder="Select Stage Group"
                    onChange={(e) => handleStageGroup(e)}
                  >
                    {list?.map((item, index) => {
                      return (
                        <option value={item} key={index}>
                          {item}
                        </option>
                      );
                    })}
                  </Select>
                </FormItem>
              </Col>
              <Col xs={24} xl={10} span={8}>
                <FormItem
                  label="Stage Name"
                  rules={[
                    {
                      required: true,
                      message: 'Select Stage Name'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Input
                    placeholder="Enter Stage Name"
                    disabled
                    value={stageName}
                  />
                </FormItem>
              </Col>
              <Col xs={24} xl={10} span={16}>
                <FormItem
                  label="Actual Yield of this Stage"
                  name="actual_yield"
                  rules={[
                    {
                      required: true,
                      message: 'Enter Actual Yield of this Stage'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Input
                    placeholder="Enter Actual Yield of this Stage"
                    onChange={(e) => handleYieldChange(e)}
                  />
                </FormItem>
              </Col>
            </Row>
            <FormItem className="text-center">
              <Space>
                <CustomButton
                  type="primary"
                  ghost
                  onClick={() => setShowModal(false)}
                >
                  Cancel
                </CustomButton>
                <CustomButton type="primary" onClick={handleSubmit}>
                  Calculate
                </CustomButton>
              </Space>
            </FormItem>
          </CustomModal>
        ) : (
          ''
        )}
        {isModal ? (
          <VerificationModal
            isModal={isModal}
            materials={materials}
            setIsModal={setIsModal}
            material={dispenseMaterial}
            setIsEdit={setIsEdit}
            page="dispense"
            handleGetAllMaterials={handleGetAllMaterials}
          />
        ) : (
          ''
        )}
      </Form>
      {isDelete ? (
        <DeleteMaterialsModel
          isModal={isDelete}
          setIsDelete={setIsDelete}
          setIsConfirm={setIsConfirm}
          isConfirm={isConfirm}
          stockBatches={stockBatches}
          setIsVerify={setIsVerify}
          isVerify={isVerify}
          stockMatID={stockMatID}
          dispenseRequestId={params.id}
          setNewStockBatches={setNewStockBatches}
          newStockBatches={newStockBatches}
          handleGetAllMaterials={handleGetAllMaterials}
          type="auto"
        />
      ) : (
        ''
      )}
    </Layout>
  );
};
export default DispensingMaterialPage;
